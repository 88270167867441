import axios from "axios";
import qs from 'qs';

class AuthService {
    
    
    login(username, password) {
        const data = {
            grant_type: "password",
            username: username,
            password: password,
            client_id: process.env.REACT_APP_PS_CLIENT_ID,
            client_secret: process.env.REACT_APP_PS_CLIENT_SECRET,
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data),
            url: process.env.REACT_APP_API_SERVER_URL + "v2/token/",
        } 
        return axios(options)
            .then(response => {
                //console.log("response", response);
                if (response.data.access_token) {
                    let today = new Date()
                    today.setHours(today.getHours() + 1)
                    const responseWithExpiry = {...response.data, expiration: today}
                    window.sessionStorage.setItem('fabel_connect_token', JSON.stringify(responseWithExpiry), {expires: 7}, {sameSite: 'Lax'})
                    
                }
                return response.data;
            })
    }
    getCurrentToken() {
        if (window.sessionStorage.getItem('fabel_connect_token')) {
            const currentTime = new Date()
            const accessTokenExpiration = new Date(JSON.parse(window.sessionStorage.getItem('fabel_connect_token')).expiration)
            if (currentTime > accessTokenExpiration) {
                //console.log("refresh me please")
            }
            return JSON.parse(window.sessionStorage.getItem('fabel_connect_token'))
        }
        else {
            return false
        }
    }
    
    logout() {
        window.sessionStorage.removeItem('SpesialtilbudShow') ;
        window.sessionStorage.removeItem('fabel_connect_token', { path: '/' })
        
    }
    

    
    

}

export default new AuthService();