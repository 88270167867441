import { useForm } from "react-hook-form";
import AuthService from "../../services/registrering/AuthService";
import configData from "../../config.json";
import UserService from "../../services/registrering/UserService";
import { useNavigate  } from "react-router-dom";
function Register() {
const navigate = useNavigate();
	const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

const onSubmit = data => {
    const userData = {
      "mobile": "47"+data.username,
      "email": data.email,
      "newsletter":"true",
    }
    //console.log("userData", userData);
    localStorage.setItem(configData.STORAGE_MSISDN, data.username);
    const accessToken = Promise.resolve(AuthService.generateAccessToken());
    accessToken.then((value) => {
       localStorage.setItem(configData.ACCESS_TOKEN, value.access_token);
    });

    UserService.register(localStorage.getItem(configData.ACCESS_TOKEN), userData).then(
      (userExist) => {
       console.log(userExist);
        if(userExist===400){
          //localStorage.setItem(configData.EXISTING_USER, 'yes');
          navigate(configData.CHURNLOGIN_PAGE);
        } else {
          //localStorage.setItem(configData.EXISTING_USER, 'no');
          localStorage.setItem(configData.STORAGE_EMAIL, data.email);
          navigate(configData.CONFIRM_PAGE);
        }
      }
    );

    
  }

return (
	<div className="reg-form-panel reg-text-left" id="product-page-container-form">
                      <div align="center" id="product-page-container-form-steps-container">
                        <div className=" reg-form-steps" id="product-page-container-form-steps">
                          <div className="reg-step reg-step-active" id="product-page-container-form-step-one">1</div>
                          <hr className="reg-step-separator" id="product-page-container-form-step-sep1" />
                          <div className="reg-step" id="product-page-container-form-step-two">2</div>
                          <hr className="reg-step-separator" id="product-page-container-form-step-sep2" />
                          <div className="reg-step" id="product-page-container-form-step-three">3</div>
                        </div>
                      </div>
                      <div className="reg-well" id="product-page-container-form-well">
                        <form id="product-page-container-main-form" onSubmit={handleSubmit(onSubmit)}>
                          <div  id="product-page-container-main-form-well-one">
                          <br />
                          </div>
                          <div className=" reg-register-inside reg-sofia-pro-reg" id="product-page-container-main-form-well-two">
                            <div className="tp-txt sofia-pro-reg font-size-16" id="product-page-container-main-form-item-one">
                              <p>Har du allerede en konto? <a id="product-page-container-main-form-logg-inn"  className="reg-sofia-pro-reg reg-font-size-16" href={configData.FABEL_MINSIDE_URL}>Logg inn </a></p>
                              
                            </div>  
                            <div className="reg-signup-input-label-mobile reg-sofia-pro-reg reg-font-size-14">
                            Mobilnummer 
                            </div>

                            <div className="reg-input reg-sofia-pro-reg reg-font-size-12" id="product-page-container-main-form-item-two">
                              <input id="product-page-container-main-form-mobile" name="username" type="tel" className="reg-sofia-pro-reg reg-font-size-12" placeholder="" {...register(configData.INPUT_PHONE, {required: true, maxLength: 8, minLength: 8})} /> {errors.username?.type === "required" && <p className="sofia-pro-reg font-size-12 error-message">Mobilnummer kreves</p>} {errors.username?.type === "maxLength" && <p className="error-message sofia-pro-reg font-size-12 ">Mobilnummeret er ugyldig</p>} {errors.username?.type === "minLength" && <p className="error-message">Mobilnummeret er ugyldig</p>}
                            </div>
                            <div className="reg-signup-input-label-email  reg-sofia-pro-reg reg-font-size-14">
                            E-postadresse 
                            </div>
                            <div className="reg-input " id="product-page-container-main-form-item-three">
                              <input type="text" id="product-page-container-main-form-epost" name="email" className="reg-sofia-pro-reg reg-font-size-12" placeholder="" {...register(configData.INPUT_EMAIL, { required: true, pattern: /\S+@\S+\.\S+/ })} /> {errors.email?.type === "required" && <p className="sofia-pro-reg font-size-12 error-message">E-postadresse er påkrevd</p>} {errors.email?.type === "pattern" && <p className="error-message sofia-pro-reg font-size-12 ">E-postadressen er ugyldig</p>}
                            </div>
                            
                            <div className="reg-checkbox  sofia-pro-reg" id="product-page-container-main-form-item-four">
                              <input className="reg-signup-checkbox" id="product-page-container-main-form-newsletter" type="checkbox" {...register(configData.INPUT_NEWSLETTER, { required: true })} />
                              <label id="product-page-container-main-form-newsletter-label" style={{marginLeft: '0px', marginTop:'5px'}} className="reg-sofia-pro-reg reg-font-size-14">Jeg har lest og godtar Fabels <a href={process.env.REACT_APP_FABEL_URL+"vilkar"}>vilkår og personvern</a>
                              </label>
                            </div>
                            
                             {errors.newsletter?.type === "required" && <p id="product-page-container-main-form-error-one" className="reg-error-message reg-sofia-pro-reg reg-font-size-14 ">Du må godta Fabels vilkår og personvern-policy for å fortsette registreringen</p>} 
                             <div  className="reg-checkbox" id="product-page-container-main-form-item-five">
                              <input  className="reg-signup-checkbox"  type="checkbox" name="news" id="nhb" />
                              <label id="product-page-container-main-form-checkbox-label" style={{marginLeft: '0px', marginTop:'13px'}} className="reg-sofia-pro-regular reg-font-size-14">Ja takk! Jeg vil gjerne ha nyhetsbrev med gode anbefalinger og tips om smarte funksjoner</label>
                            </div>
                            <div className="reg-gap" id="product-page-container-main-form-gap"></div>
                            <div className="reg-submit-button" id="product-page-container-main-form-submit">
                              <button type="submit" id="product-page-container-main-form-submit-button" className="reg-sofia-pro-bold reg-font-size-18 reg-fm-submit-btn">Opprett konto</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
	);

}

export default Register;