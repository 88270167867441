import React,{useEffect , useState} from "react";
import configData from "../../config.json";
import Header from "./Header.js";
import Footer from "./Footer.js";
import LoginForm from "./LoginForm.js";
import BaseProduct from "./BaseProduct.js";
import CampaignProduct from "./CampaignProduct.js";
import PartnerProduct from "./PartnerProduct.js";
import "../../styles/registrering/churnlogin.css";

import ProductService from "../../services/registrering/ProductService";
import { useNavigate  } from "react-router-dom";

import setBodyColor from './setBodyColor'


function ChurnLogin() {
  const navigate = useNavigate();
  //const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND); 
  const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
  const productSlug = localStorage.getItem(configData.STORAGE_PRODUCT_TAG);
  const [productInfo, setproductInfo] = useState({});
 
  

  

  

  useEffect(() => {
     
    if (productId === null) {
      navigate(configData.PRODUCT_PAGE);
    }
  })

  useEffect(() => {
    if (productSlug === "undefined" || productSlug === null) {
      navigate(configData.PRODUCT_PAGE);
    }
    ProductService.getProductByTag(productSlug).then(
      (product) => {
        //console.log(product);
        setproductInfo(product);
        localStorage.setItem(configData.PRODUCT_BACKGROUND, product.product_background);
      }
    );
  }, [productSlug, navigate])



  productInfo && (productInfo.type==='public_campaign_product' || productInfo.type==='partner_product')  ? setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_PURPLE}) : setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN}) ;
  
  return (
  	<div id="product-page-main-container" className={productInfo && (productInfo.type==='public_campaign_product' || productInfo.type==='partner_product')  ? 'product_background-light' : 'product_background-dark'}>

      <Header colorFromParent="primary-color-pallete" />
        <div className="container churnlogin-main" id="product-page-container" >
          <div className="row" id="product-page-container-row-one">
            <div className="col-12" id="product-page-container-row-one-column">
              <div className="well  text-left" id="product-page-container-row-one-well">
                <a className="font-size-12" id="product-page-container-row-one-tilbake" href={configData.PRODUCT_PAGE}><u>TILBAKE</u></a>
              </div>
            </div>
          </div>
          <div className="row" id="product-page-container-row-two">
            {productInfo ?
            <div className="left-side" id="product-page-container-row-two-column-one">
              <div className="well text-center" id="product-page-container-row-two-well-one">
                <div className="row" id="product-page-container-row-two-row-one">
                  {productInfo.type==='partner_product' ? 
                      <PartnerProduct productinfo={productInfo} />
                      
                  : productInfo.type==='base_product' ? 
                    <>
                      <BaseProduct productinfo={productInfo} />
                    </>
                  :
                      <CampaignProduct productinfo={productInfo} />
                  }
                </div>

                
                

                
                <div className="row " id="product-page-container-row-two-row-six">
                  <div className="col-12" id="product-page-container-row-two-row-six-column">
                    
                    <div className=" text-center terms_and_cond secondary-color" id="product-page-container-row-two-row-six-well-two">
                      <h5 className="text-left sofia-pro-reg font-size-14 " id="product-page-container-description-container">
                      {productInfo.terms_and_conditions ? 

                         productInfo.terms_and_conditions.terms.map((terms, fkey) =>(
                            <>
                            {terms}
                            <br /><br />
                            </> 
                          ))
                       : "" }
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : '' }
            <div className="middle-side" id="product-page-container-row-two-column-two">
            &nbsp;
            </div>
            <div className="right-side" id="product-page-container-row-two-column-three">
              <div className="well text-right" id="product-page-container-row-two-column-three-well">
                <div className="row" id="product-page-container-row-two-row-one">
                  <div className="col-12" id="product-page-container-row-two-row-one-column">
                    
                    <LoginForm />

                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row" id="product-page-container-row-five">
            <div className="col-12" id="product-page-container-row-five-column">
              <div className="gap" id="product-page-container-row-five-gap"></div>
              <div className="gap" id="product-page-container-row-five-gap"></div>
              <div className="gap" id="product-page-container-row-five-gap"></div>
              <div className="gap" id="product-page-container-row-five-gap"></div>
            </div>
          </div>
        </div>

      <Footer />
      
    </div>
  	)
}
export default ChurnLogin;