import axios from "axios";
//import configData from "../config.json";
import qs from 'qs';
//const API_URL = configData.API_SERVER_URL; 
class PaymentService {
    
    async create(access_token, user_data) {
        const options = {
            method: "POST",
            data: qs.stringify(user_data),
            headers: {
                "Authorization": "Bearer " + access_token
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/user/stripe-payment"
        }
        return axios(options)
        .then(response => {
            return response.data;
        }).catch(function (error) { 
            return error.response.status;
        })


       
        
    }

    async stripeIntent(access_token) {
        //return access_token;
        const options = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/payment/stripe/intent"
        }
        return axios(options)
        .then(response => {
            return response.data;
        }).catch(function (error) { 
            return error.response.status;
        })

        
    }

    async stripePayment(access_token, stripeData) {
        const options = {
            method: "POST",
            data: stripeData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/payment/stripe/subscription"
        }
        return axios(options)
        .then(response => {
            return response.data;
        }).catch(function (error) { 
            return error.response.status;
        })

        
    }


    async strex(access_token, strexData){
       const options = {
            method: "POST",
            data: strexData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/payment/strex/subscription"
        }
        return axios(options)
        .then(response => {
            return response.data;
        }).catch(function (error) { 
            return error.response.status;
        })     
    }

    async vippsIntent(access_token, vippsData) {
        //return access_token;
        const options = {
            method: "POST",
            data: {"product_id": vippsData.product_id, "campaign_code": vippsData.campaign_code, "redirect_url": vippsData.redirect_url},
            //data: vippsData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/payment/vipps/intent"
        }
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        })

        
    }

    async vippsSubscription(access_token, vippsData) {
        //return access_token;
        const options = {
            method: "POST",
            data: {"product_id": vippsData.product_id, "utm_campaign": vippsData.utm_campaign, "reference": vippsData.reference},
            //data: vippsData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/payment/vipps/subscription"
        }
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        })

        
    }
    
    

}

export default new PaymentService();