import React,{useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StripeForm from "./StripeForm"
import { useNavigate  } from "react-router-dom";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "../../styles/registrering/payment.css";
import PaymentService from "../../services/registrering/PaymentService";
import setBodyColor from './setBodyColor'
import configData from "../../config.json";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Payment(){ 
  const navigate = useNavigate();
  //const [vippsButtonUrl, setVippsButtonUrl] = useState(null);
  const [state, setMethodState] = useState({
    mobileMethod: false,
    stripeMethod: true,
    vippsMethod: false,
  });

  //const [mystate, setMyState] = useState(null);
  const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
  //const existingUser = localStorage.getItem(configData.EXISTING_USER);
  //console.log('productId',productId);
  const orderPrice = localStorage.getItem(configData.STORAGE_ORDER_PRICE);  
  const accessToken = localStorage.getItem(process.env.REACT_APP_USER_ACCESS_TOKEN);
  /*const vippsConfirmUrl = () => {
    window.location.href = vippsButtonUrl;
  }*/
  const handleVippsSubmit = async(data) => {
    const vippsData = {
        "product_id": productId,
        "campaign_code": "UTMCAMPAIGN",
        "redirect_url": "https://test-registrering-new.fabel.no/vipps-success"
      }
    //return;
    PaymentService.vippsIntent(accessToken, vippsData).then(
      (vipps) => {
        //console.log('vipps',vipps);
        if(vipps.status===200){
          localStorage.setItem("vippsreference", vipps.data.reference); 
          window.location.href = vipps.data.confirmation_url;
          //setVippsButtonUrl(vipps.data.confirmation_url);
          //navigate(configData.REGISTRATION_COMPLETE_PAGE);
        } else {
          //navigate(configData.REGISTRATION_COMPLETE_PAGE);
        }
      }
    );
  }
  const handleStrexSubmit = async (data) => {
    
    const strexData = {
        "product_id": productId,
        "utm_campaign": ""
      }
    //return;
    PaymentService.strex(accessToken, strexData).then(
      (strex) => {
        //console.log('strex',strex);
        if(strex===500){
          navigate(configData.REGISTRATION_COMPLETE_PAGE);
        } else {
          navigate(configData.REGISTRATION_COMPLETE_PAGE);
        }
      }
    );
  }

  

  
  useEffect(() => {
    PaymentService.stripeIntent(accessToken).then(
      (intent) => {
        console.log(intent.client_secret);
        if(localStorage.getItem("storeintentsecret")==='' || localStorage.getItem("storeintentsecret")===null){
          //console.log("intent.client_secret", intent.client_secret);
          localStorage.setItem("storeintentsecret", intent.client_secret);
          window.location.reload(false);
        }
        

      }

    );


  })

  const options = {
    clientSecret: localStorage.getItem("storeintentsecret"),
   
  };
  
  setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN})
        //console.log(localStorage.getItem("storeintentsecret"));
  return (
    <div id="payment-main-container" className="product_background-dark">
      <Header colorFromParent="primary-color-pallete"  />
      <div className="container" id="payment-container">
        <div className="col-3" id="payment-container-column-one" >&nbsp;</div>
        <div className="col-6" id="payment-container-column-two">
          <div className="well text-right"  id="payment-container-well-one">
          &nbsp;
          </div>
          <div className="well text-right"  id="payment-container-well-two">
                <div className="row" id="payment-container-row-one">
                  <div className="col-12" id="payment-container-row-one-column">
                    <div className="payment-form-panel text-left"  id="payment-container-step-container">
                      <div align="center" id="payment-container-steps">
                        <div className="well form-steps" id="payment-container-step">
                          <div className="step step-active" id="payment-container-step-one">1</div>
                          <hr className="step-separator" id="payment-container-sep-one" />
                          <div className="step step-active " id="payment-container-step-two">2</div>
                          <hr className="step-separator" id="payment-container-sep-two" />
                          <div className="step step-active" id="payment-container-step-three">3</div>
                        </div>
                      </div>
                      <div className="well" id="payment-container-well-three">
                          <div className="well" id="payment-container-well-four">
                          <br />
                          </div>
                          <div className="well" id="payment-container-well-five">
                            <div className="text-center sofia-pro-bold font-size-18" id="payment-container-heading">
                              <p id="payment-container-heading-text">Velg betalingsmåte</p>
                            </div>  
                            <div className="gap" id="payment-container-gap-one"></div>
                            <div className="payment-div" id="betaling-mobil">
                              <div className="payment-option" id="payment-container-mobile">
                              
                                <input type="radio" id="payment-container-mobile-radio" className="payment_method" onChange={e => {}} checked={state.mobileMethod} onClick={()=> setMethodState({ mobileMethod: true, stripeMethod: false, vippsMethod: false }) } name="payment_method" value="mobile" />
                                <div className="radio-right payment-label sofia-pro-reg font-size-18" id="payment-container-mobile-mobilregningen">
                                  <p id="payment-container-mobile-mobilregningen-text">Betal med mobilregningen</p>
                                </div>
                              </div>
                              {state.mobileMethod ? 
                                <div className="drop" id="drop2">
                                  <div className="drop-content" id="payment-container-drop2">
                                    <div id="payment-container-drop2-text">
                                      <p id="payment-container-drop2-text-p">Abonnementet vil belaste din mobilregning</p>
                                    </div>
                                    <div className="submit-button" id="payment-container-drop2-submit-button">
                                      <button id="payment-container-drop2-button" className="btn-main payment-submit-button  sofia-pro-bold font-size-20"  type="button" name="payment_strex">
                                        <div  className="spinner hidden" id="spinner"></div>
                                        <span id="button-text " onClick={() => handleStrexSubmit()}>FULLFØR</span>
                                      </button>
                                    </div>
                                    
                                    <div className="left-show" id="payment-container-mobile-info">
                                      <p className="left-info sofia-pro-reg font-size-12" id="payment-container-mobile-info-text"> 
                                      Viktig informasjon: Etter prøveperioden fortsetter abonnementet automatisk til {orderPrice} kr/måned. Det er ingen bindingstid, og du kan når som helst avslutte ditt abonnement ved å logge inn på kontoen din på Fabel.no </p>
                                    </div>
                                  </div>
                                </div> 
                              : "" }
                            </div>
                            <div className="gap" id="payment-container-gap-two"></div>
                            <div className="payment-div" id="payment-container-card-payment">
                              <div className="payment-option" id="payment-container-card">
                              
                              <input type="radio" id="payment-container-card-radio" className="payment_method" onChange={e => {}} checked={state.stripeMethod} onClick={()=> setMethodState({ mobileMethod: false, stripeMethod: true, vippsMethod: false }) }  name="payment_method" value="stripe" />
                                <div id="payment-container-card-radio-label" className="radio-right payment-label  sofia-pro-reg font-size-18">
                                  <i className="payment-icon" id="payment-container-card-radio-icon">
                                    <img src="./img/cc-icon.png" id="payment-container-card-radio-image" alt="" />
                                  </i>
                                  <p id="payment-container-card-radio-label-text">Betal med kort</p>
                                </div>
                              </div>
                              {state.stripeMethod ? 
                                <div className="drop" id="drop3">
                                  <Elements stripe={stripePromise} options={options} >
                                    <StripeForm />

                                  </Elements>
                                  
                                  <div  id="payment-container-card-info-cont" className="left-show sofia-pro-reg font-size-20 text-center">
                                    <p id="payment-container-card-info-p1">&nbsp;</p>
                                    <p id="payment-container-card-info-p2" className="sofia-pro-reg font-size-14">
                                    Viktig informasjon: Etter prøveperioden fortsetter abonnementet automatisk til {orderPrice} kr/mnd. Det er ingen bindingstid, og du kan når som helst avslutte ditt abonnement ved å logge deg inn på fabel.no</p>
                                  </div>
                                </div> 
                              : ""}
                            </div>
                            <div className="gap" id="payment-container-gap-three"></div>
                            <div className="payment-div" id="payment-container-vipps-payment">
                              <div className="payment-option" id="payment-container-vipps">
                              
                              <input type="radio" id="payment-container-vipps-radio" className="payment_method" onChange={e => {}} checked={state.vippsMethod} onClick={()=> setMethodState({ mobileMethod: false, stripeMethod: false, vippsMethod: true  }) }  name="payment_method" value="stripe" />
                                <div id="payment-container-vipps-radio-label" className="radio-right payment-label  sofia-pro-reg font-size-18">
                                  <i className="payment-icon" id="payment-container-vipps-radio-icon">
                                    <img src="./img/cc-icon.png" id="payment-container-vipps-radio-image" alt="" />
                                  </i>
                                  <p id="payment-container-vipps-radio-label-text">Betal med vipps</p>
                                </div>
                              </div>
                              {state.vippsMethod ? 
                                <div className="drop" id="drop4">
                                  <div className="submit-button" id="payment-container-vipps">

                                      <button id="payment-container-vipps-button" className="btn-main payment-submit-button  sofia-pro-bold font-size-20"  type="button" name="payment_strex">
                                        <div  className="spinner hidden" id="spinner"></div>
                                        <span id="button-text-vipps" onClick={() => handleVippsSubmit()}>FULLFØR</span>
                                        
                                      </button>
                                  </div>
                                  
                                  <div  id="payment-container-vipps-info-cont" className="left-show sofia-pro-reg font-size-20 text-center">
                                    <p id="payment-container-vipps-info-p1">&nbsp;</p>
                                    <p id="payment-container-vipps-info-p2" className="sofia-pro-reg font-size-14">
                                    Viktig informasjon: Etter prøveperioden fortsetter abonnementet automatisk til {orderPrice} kr/mnd. Det er ingen bindingstid, og du kan når som helst avslutte ditt abonnement ved å logge deg inn på fabel.no</p>
                                  </div>
                                </div> 
                              : ""}
                            </div>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          <div className="well text-right" id="payment-container-well-six">
          &nbsp;
          </div>
        </div>
        <div className="col-3"  id="payment-container-column-three"></div>
      </div>
      
      <Footer />
    </div>
    )
}