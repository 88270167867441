import AppStoreIcon from '../../images/app_store_commons_badge_transparent.png';
import PlayStoreIcon from '../../images/google_play_commons_badge_transparent.png';
import "../../styles/registrering/footer.css";
import "../../styles/registrering/footer.css";
import configData from "../../config.json";
import {Link} from "react-router-dom";
function Footer() {
  const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND);
  return (
      <footer id="footer-main-container">
        <div id="footer-content" className={productBackground ? productBackground : "reg-footer"}>
          <div id="footer-content-column-one">
            <Link id="footer-content-column-one-gratis" to="/">Prøv Fabel gratis</Link>
            <a id="footer-content-column-one-om-fabel" rel="noreferrer" href={process.env.REACT_APP_FABEL_URL+"om-oss-abonnementstjenesten-fabel"} target="_blank">Om Fabel</a>
            <a id="footer-content-column-one-hjelp" rel="noreferrer" href={process.env.REACT_APP_FABEL_URL+"kundeservice"} target="_blank">Hjelp</a>
          </div>
          <div id="footer-content-column-two">
            <a id="footer-content-column-two-appstore" href={configData.IOS_APP_URL}>
              <img id="footer-content-column-two-appicon" src={AppStoreIcon} alt="" />
            </a>
            <a id="footer-content-column-two-playstore" href={configData.ANDROID_APP_URL}>
              <img id="footer-content-column-two-playicon" src={PlayStoreIcon} alt="" />
            </a>
          </div>
        </div>
      </footer>
    );
}

export default Footer;