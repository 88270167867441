import { useForm } from "react-hook-form";
import React,{useState} from "react";
import configData from "../../config.json";
import { yupResolver } from "@hookform/resolvers/yup"
import AuthService from "../../services/registrering/AuthService";
import UserService from "../../services/registrering/UserService";
import { useNavigate  } from "react-router-dom";
import * as Yup from "yup"
function LoginForm() {
    const navigate = useNavigate();
  const msisdn = localStorage.getItem(configData.STORAGE_MSISDN);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const formSchema = Yup.object().shape({
    password: Yup.string().required(configData.PASSWORD_REQUIRED).min(configData.PASSWORD_MIN_LENGTH, configData.PASSWORD_SHORT),
  })
	const formOptions = {
    resolver: yupResolver(formSchema)
  }
  const {
    register,
    handleSubmit,
    formState
  } = useForm(formOptions);
  const {
    errors
  } = formState;

  const submitLogin = data => {
    const username = '47'+data.username;
    const password = data.password;
    //return;
    AuthService.login(username, password).then(
      (user) => {
        console.log(user);
        if(user.status===200){
          setErrorMessage("");
          localStorage.setItem(configData.EXISTING_USER, 'yes');
          localStorage.setItem(process.env.REACT_APP_USER_ACCESS_TOKEN, user.data.access_token);
          setSuccessMessage(configData.SUCCESSFULLY_LOGGEDIN);
          UserService.getUserSubscription(user.data.access_token).then((subscription) => {
            if(subscription.status===200){
              window.location.href = process.env.REACT_APP_FABEL_MINSIDE_URL;
              return;
            } else {
              navigate(configData.PAYMENT_PAGE);
            }
          })
          
          
        } else {
          // console.log('!200', user);
          setSuccessMessage("");
          setErrorMessage(configData.WRONG_LOGIN_CREDENTIALS);
        }
      }
    );
  }

return (

	<div className="form-panel text-left" id="product-page-container-form">
                      <div align="center" id="product-page-container-form-steps-container">
                        <div className=" form-steps" id="product-page-container-form-steps">
                          <div className="step step-active" id="product-page-container-form-step-one">1</div>
                          <hr className="step-separator" id="product-page-container-form-step-sep1" />
                          <div className="step" id="product-page-container-form-step-two">2</div>
                          <hr className="step-separator" id="product-page-container-form-step-sep2" />
                          <div className="step" id="product-page-container-form-step-three">3</div>
                        </div>
                      </div>
                      <div className="well" id="product-page-container-form-well">
                          
                        <form id="product-page-container-main-form"  onSubmit={handleSubmit(submitLogin)}>
                          <div  id="product-page-container-main-form-well-one">
                          <br />
                          </div>

                          <div className=" register-inside sofia-pro-reg" id="product-page-container-main-form-well-two">
                            <div id="churn-page-form-text-one" className="sub-title sofia-pro-reg font-size-12"> Det finnes allerede en konto registrert med dette mobilenummeret. <br />
                            < div id="churn-page-form-text-two"  className="bd-text sofia-pro-bold font-size-12">Logg inn for &aring; aktivere abonnementet.</div>
                            </div> 
                            <div className="signup-input-label-mobile sofia-pro-reg font-size-14">
                            Mobilnummer 
                            </div>

                            <div className="input sofia-pro-reg font-size-12" id="product-page-container-main-form-item-two">
                              <input id="churn-page-form-input-mobilenumber" name="username" type="tel" placeholder="Mobilnummer" {...register(configData.INPUT_PHONE, {required: true, maxLength: 8, minLength: 8, value: msisdn})} /> {errors.msisdn?.type === "required" && <p className="error-message sofia-pro-reg font-size-12 ">Mobilnummer kreves</p> } {errors.msisdn?.type === "maxLength" && <p className="error-message sofia-pro-reg font-size-12 ">Mobilnummeret er ugyldig</p> } {errors.msisdn?.type === "minLength" && <p className="sofia-pro-reg font-size-12 error-message">Mobilnummeret er ugyldig</p> }
                            </div>
                            <div className="signup-input-label-email  sofia-pro-reg font-size-14">
                            Husker du passordet ditt?
                            </div>
                            <div className="input " id="product-page-container-main-form-item-three">
                              <input id="churn-page-form-input-password" name="password" type={configData.INPUT_PASSWORD} {...register(configData.INPUT_PASSWORD)} />
                            </div>
                            
                            <div id="churn-page-form-error-password" className="error-message sofia-pro-reg font-size-12 ">{errors.password?.message}</div>
                            <div className="sofia-pro-reg font-size-12" id="churn-page-form-forgot-pwd-container">
                            <label id="churn-page-form-forgot-pwd-label">
                              <div id="churn-page-form-forgot-pwd-text" className="sofia-pro-reg font-size-12">Har du glemt passordet ditt?</div>
                              <div id="churn-page-form-forgot-new-pwd-text" className="sofia-pro-reg font-size-12">
                              <a id="churn-page-form-forgot-pwd-anchor" className="new_password_link" href={configData.FORGOT_PASSWORD_PAGE}>
                              Trykk her for &aring; f&aring; tilsendt nytt passord p&aring; SMS</a></div>
                            </label>
                            <div className="gap" id="churn-page-form-forgot-pwd-gap"></div>
                            <div id="churn-page-form-forgot-pwd-text" className="sofia-pro-reg font-size-12"></div>
                          </div>
                          <div className="gap" id="churn-page-form-gap"></div>
                          {errorMessage ? <p id="churn-page-form-error-message" className="error-message sofia-pro-reg font-size-12 ">{errorMessage}</p> : ""}
                          {successMessage ? <p className="success-message">{successMessage}</p> : ""}
                            
                            <div className="submit-button" id="product-page-container-main-form-submit">
                              <button type="submit" id="product-page-container-main-form-submit-button" className="sofia-pro-bold font-size-18 fm-submit-btn">Opprett konto</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
	);

}

export default LoginForm;