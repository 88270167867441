import React,{useState, useEffect} from "react";
import Header from "./Header.js";
import configData from "../../config.json";
import { useNavigate, useParams  } from "react-router-dom";
import Footer from "./Footer.js";
import "../../styles/registrering/products.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ProductService from "../../services/registrering/ProductService";
import setBodyColor from './setBodyColor'
function Products() {
	const navigate = useNavigate();
	//const [searchParams] = useSearchParams();
	const {utmcode} = useParams();
  	
	localStorage.setItem(configData.PRODUCT_BACKGROUND, "");
  	const [allProducts, setAllProducts] = useState([]);
  	const [logoChange, setLogoChange] = useState(null);
	const selectProduct = (product, price, tag) => {
		localStorage.setItem(configData.STORAGE_PRODUCT_ID, product);
		localStorage.setItem(configData.STORAGE_ORDER_PRICE, price);
		navigate("/"+tag);
	} 
	useEffect(() => {
		//setLogoChange("./img/fabel-dark-logo.png");
		setLogoChange(process.env.REACT_APP_LOGO_URL);
		ProductService.getAllUtmProducts(utmcode).then(
	      (product) => {	
	        setAllProducts(product)
	        console.log(product[0].trial_weeks);
	        localStorage.setItem(configData.UTM_WEEKS, product[0].trial_weeks);
	      }
	    );
	}, [utmcode])

	setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN})
	console.log(window.location.hostname);
  return (
  	<div className="productApp" id="productApp">
	  <Header logo={logoChange} colorFromParent="tertiary-color-pallete" />

	  <main>

					<div className="container-prd-slctr">
					  <h1 className="page-title">Våre abonnement</h1>
					  <h5 className="title-text">
					    Velg det abonnementet som passer dine lyttevaner best.
					    <br />
					    Gratis prøveperiode gjelder kun nye kunder.
					  </h5>
					  <div className="icon-top">
					    <div className="">
					      <img
					        id="one-book-icon"
					        src="https://registrering.fabel.no/static/images/en_bok_ikon.png"
					        alt=""
					      />
					      <img
					        id="lytt-sammen-icon"
					        src="https://registrering.fabel.no/static/images/lytt_sammen.png"
					        alt=""
					      />
					    </div>
					  </div>
					  <div className="product-selector">
					    {allProducts ? 
		  				<>
		  				{allProducts.map((value, key) => (
					    <div className="product-wrapper ">
					      {value.type==='public_campaign_product' ? <div className="best-buy-tag">{value.best_buy_text}</div> : ''}

					      <div className={value.type+"-product-sub-panel product-card"} id="product-id-201">
					        <div className="top-div">
					          <h1 className="product-title">{value.name}</h1>
					          <span className="product-subtitle">{value.subtitle}
					          </span>
					          	
					          
					          <span className="product-small_screenssubtitle-mobile mobile-only">
					            {value.subtitle_small_screens}
					          </span>
					          <span className="product-subtitle-mobile">
					            {value.subtitle_small_screens_second}
					          </span>
					          <div className="product-price-div">
					            <span className="product-price"> {value.price} kr</span>
					            <span className="product-price-prmonth">/mnd</span>
					          </div>
					          
					            <div className={value.type==='public_campaign_product' ? 'sofia-pro-reg font-size-14 font-color-green product-streams' : 'sofia-pro-reg font-size-16 font-color-pink product-streams' }>{value.streams}</div>
					          
					        </div>
					        <div className="bottom-div">
					          {value.features 
      							? value.features.included ? 
      							value.features.included.map((feature, fkey) => (
						          <div className="product-property">
						            <FontAwesomeIcon icon={faCircle} className="fontawesome-circle"   />
						            {feature}
						          </div>
					          	))
      							: "" 
      							: ""} 
					          
					          
					        </div>
					        	
			  				
			  					<button className="bottom-btn" type="submit" onClick={()=> selectProduct(value.product_id, value.price, value.tag)} name="product_id" value="201">
				            	Prøv gratis i {value.trial_weeks} uker
				          		</button>
				          	
					      </div>
					    </div>
					    ))}
						  	</>
				  		: ""}
					    
					    
					  </div>
					  <div className="icon-top">
					    <div>
					      <img
					        id="lytt-sammen-icon-bottom"
					        src="https://registrering.fabel.no/static/images/lytt_sammen.png"
					        alt=""
					      />
					    </div>
					  </div>
					  <div className="disclaimer-prod">
					    <p>
					      *Samtidig strømming betyr hvor mange personer som kan lytte samtidig, på
					      hver sin enhet.
					    </p>
					  </div>
					</div>
					

					</main>
	  <Footer />
	</div>
  );

}

export default Products;