import "../../styles/registrering/header.css";
import logoImage from '../../images/fabel-light-logo.png';

import configData from "../../config.json";
import React, {useState} from "react";
function Header(match) {
	const userAccessToken = localStorage.getItem(process.env.REACT_APP_USER_ACCESS_TOKEN);
	//console.log("userAccessToken", userAccessToken);
	const [menueExpand, isMenUExpand] = useState(false);
	const [hamImage, setHamImage] = useState(configData.MENU_HAM_IMAGE);
	const toggleHamMenu = (event) => {
		isMenUExpand(!menueExpand)
		setHamImage(menueExpand ? configData.MENU_HAM_IMAGE  : configData.MENU_CLOSE_IMAGE);
	}
	//console.log(hamImage);
	const logo = match.logo
	  return (
		<div className="reg-nav" id="header-nav">
			<div id="top-nav" className={"reg-top-nav "+match.colorFromParent}>
				<div className="reg-hidden-sm" id="top-nav-sm-nav">
					<div className="reg-container reg-desktop-only" id="hidden-sm-child-container">
						<div className="reg-row" id="hidden-sm-row">
							<div className="reg-col-8 reg-text-left" id="hidden-sm-row-item-one">
								<a href={process.env.REACT_APP_FABEL_URL}><img id="nav-logo" src={logo ? logo : logoImage} alt="fabel-logo" /></a>
							</div>
							<div className="reg-col-2 reg-text-center" id="hidden-sm-row-item-two">
								<a id="hidden-sm-nav-kundeservice" href={process.env.REACT_APP_FABEL_URL+"kundeservice"}  className={match.colorFromParent}>KUNDESERVICE</a>
							</div>

							{userAccessToken==='' ? <div className="reg-col-2 reg-text-center" id="hidden-sm-row-item-three">
								<a id="hidden-sm-nav-logginn" href={configData.FABEL_MINSIDE_URL} className="reg-log-inn">LOGG INN</a>
							</div> : <div className="reg-col-2 reg-text-center" id="hidden-sm-row-item-three">
								<a id="hidden-sm-nav-logginn" href={configData.LOGOUT_PAGE} className="reg-log-inn">LOGG UT</a>
							</div> }
						</div>
					</div>
				</div>
				<div className="reg-hidden-lg"  id="top-nav-lg-nav">
					<div  id="hidden-lg-child-container">
						<div className="reg-row" id="hidden-lg-row">
							<div className="reg-col-8 reg-text-center" id="hidden-lg-row-item-one">
								<div className="reg-logo-left-section">
									&nbsp;
								</div>
								<div className="reg-mobile-logo">
									<a href={process.env.REACT_APP_FABEL_URL}><img id="nav-logo" src={logo ? logo : logoImage} alt="fabel-logo" /></a>
								</div>
								<div className="reg-ham-container reg-mobile-only reg-hide-on-tablet reg-hide-on-desktop" id="hidden-lg-ham">
									<img onClick={toggleHamMenu} src={hamImage} alt="fabel-logo"  id="hidden-lg-fabel-logo" />
								</div>
							</div>
							
							 
						</div>
					</div>
					{menueExpand===true ?
					<div className="reg-row" id="ham-menu-row">
						<div className="reg-col-12 reg-text-center" id="ham-menu-row-column">
							<div className="reg-menu-container" id="ham-menu-row-container">
								<div className="reg-menu-items" id="ham-menu-row-items">
									<div id="ham-menu-row-item-one">
										<a id="ham-menu-row-item-one-kunde" href="https://fabel.no/kundeservice/" className="reg-customer-srvce">KUNDESERVICE</a>
									</div>

									<div id="ham-menu-row-item-two">
										{userAccessToken==='' ? <div className="reg-col-2 reg-text-center" id="hidden-sm-row-item-three">
											<a id="ham-menu-row-item-two-logginn" href="https://minside.fabel.no/" className="reg-log-inn">LOGG INN</a>
										</div> : <div className="reg-col-2 reg-text-center" id="hidden-sm-row-item-three">
											<a id="ham-menu-row-item-two-logginn" href={configData.LOGOUT_PAGE}  className="reg-log-inn">LOGG UT</a>
										</div> }
										
									</div>
								</div>
							</div>
						</div>
					</div>
					: '' }
				</div>
			</div>
		</div>
		
	  );
}

export default Header;

