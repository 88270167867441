import {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import PaymentService from "../../services/registrering/PaymentService";
import configData from "../../config.json";
function VippsSuccess() {
	// console.log("product_id");
	const queryParameters = new URLSearchParams(window.location.search)
  	const productId = queryParameters.get("product_id")
  	//const name = queryParameters.get("name")
	const navigate = useNavigate();
	
	const accessToken = localStorage.getItem(process.env.REACT_APP_USER_ACCESS_TOKEN);
	const vippsData = {
        "product_id": productId,
        "utm_campaign": "UTMCAMPAIGN",
        "reference": localStorage.getItem("vippsreference")
      }

	useEffect(() => {
		console.log("vippsreference", localStorage.getItem("vippsreference"));
		

		PaymentService.vippsSubscription(accessToken, vippsData)
		.then((vipps) => {
			//console.log("vipps", vipps);
			if(vipps.status===200){
	          //window.location.href = vipps.data.confirmation_url;
	          //setVippsButtonUrl(vipps.data.confirmation_url);
	          navigate(configData.REGISTRATION_COMPLETE_PAGE);
	        } else {
	          //navigate(configData.REGISTRATION_COMPLETE_PAGE);
	        }
		})

	})
	
	
  return (
	<div></div>
  	)
}
export default VippsSuccess;