import React,{useState, useEffect} from "react";
import logoImage from '../../images/fabel-dark-logo.png';
import Header from "./Header.js";
import configData from "../../config.json";
import { useNavigate  } from "react-router-dom";
import Footer from "./Footer.js";
import "../../styles/registrering/products.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ProductService from "../../services/registrering/ProductService";
import setBodyColor from './setBodyColor'
function Products() {
	const navigate = useNavigate();
	//const [searchParams] = useSearchParams();
	localStorage.setItem(configData.UTM_WEEKS, "");
	localStorage.setItem(configData.PRODUCT_BACKGROUND, "");
  	const [allProducts, setAllProducts] = useState([]);
  	//const [allProductsMobile, setAllProductsMobile] = useState([]);
  	//const allProductsCopy = [...allProductsMobile];
  	//allProductsCopy.sort((a, b) => b.type.localeCompare(a.type));
  	//console.log(allProducts, allProductsCopy);
  	const [logoChange, setLogoChange] = useState(null);
	const selectProduct = (product, price, tag) => {
		localStorage.setItem(configData.STORAGE_PRODUCT_ID, product);
		localStorage.setItem(configData.STORAGE_ORDER_PRICE, price);
		//navigate(configData.HOME_PAGE);
		navigate("/"+tag);
	} 
	useEffect(() => {
		//setLogoChange("./img/fabel-dark-logo.png");
		setLogoChange(logoImage);
		ProductService.getAllProducts().then(
	      (product) => {	
	      	//setAllProductsDesktop(product);
	      	//console.log("product", product)
	        
	        //setAllProductsMobile(product);
	        setAllProducts(product)
	      }
	    );
	}, [])
	setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN})
	console.log(window.location.hostname);
  return (
  	<div className="reg-productApp" id="productApp">
	  <Header logo={logoChange} colorFromParent="reg-tertiary-color-pallete" />

	  <main>

					<div className="reg-container-prd-slctr">
					  <h1 className="reg-page-title">Våre abonnement</h1>
					  <h5 className="reg-title-text">
					    Velg det abonnementet som passer dine lyttevaner best.
					    <br />
					    Gratis prøveperiode gjelder kun nye kunder.
					  </h5>
					  <div className="reg-icon-top">
					    <div className="">
					      <img
					        id="one-book-icon"
					        src="https://registrering.fabel.no/static/images/en_bok_ikon.png"
					        alt=""
					      />
					      <img
					        id="lytt-sammen-icon"
					        src="https://registrering.fabel.no/static/images/lytt_sammen.png"
					        alt=""
					      />
					    </div>
					  </div>
					  <div className="reg-product-selector">
					    {allProducts ? 
		  				<>
		  				{allProducts.map((value, key) => (
					    <div className="reg-product-wrapper ">
					      {value.type==='public_campaign_product' ? <div className="reg-best-buy-tag">{value.best_buy_text}</div> : ''}

					      <div className={"reg-"+value.type+"-product-sub-panel reg-product-card"} id="product-id-201">
					        <div className="reg-top-div">
					          <h1 className="reg-product-title">{value.name}</h1>
					          <span className="reg-product-subtitle">{value.subtitle}
					          </span>
					          	
					          
					          <span className="reg-product-small_screenssubtitle-mobile reg-mobile-only">
					            {value.subtitle_small_screens}
					          </span>
					          <span className="reg-product-subtitle-mobile">
					            {value.subtitle_small_screens_second}
					          </span>
					          <div className="reg-product-price-div">
					            <span className="reg-product-price"> {value.price} kr</span>
					            <span className="reg-product-price-prmonth">/mnd</span>
					          </div>
					          
					            <div className={value.type==='public_campaign_product' ? 'reg-sofia-pro-reg reg-font-size-14 reg-font-color-green reg-product-streams' : 'reg-sofia-pro-reg reg-font-size-16 reg-font-color-pink reg-product-streams' }>{value.streams}</div>
					          
					        </div>
					        <div className="reg-bottom-div">
					          {value.features 
      							? value.features.included ? 
      							value.features.included.map((feature, fkey) => (
						          <div className="reg-product-property">
						            <FontAwesomeIcon icon={faCircle} className="reg-fontawesome-circle"   />
						            {feature}
						          </div>
					          	))
      							: "" 
      							: ""} 
					          
					          
					        </div>
					        	
			  				
			  					<button className="reg-bottom-btn reg-product-select-button" type="button" onClick={()=> selectProduct(value.product_id, value.price, value.tag)} name="product_id" value={value.product_id}>
				            	Prøv gratis i {value.trial_weeks} uker
				          		</button>
				          	
					      </div>
					    </div>
					    ))}
						  	</>
				  		: ""}
					    
					    
					  </div>
					  <div className="reg-icon-top">
					    <div>
					      <img
					        id="lytt-sammen-icon-bottom"
					        src="https://registrering.fabel.no/static/images/lytt_sammen.png"
					        alt=""
					      />
					    </div>
					  </div>
					  <div className="reg-disclaimer-prod">
					    <p>
					      *Samtidig strømming betyr hvor mange personer som kan lytte samtidig, på
					      hver sin enhet.
					    </p>
					  </div>
					</div>
					

					</main>
	  <Footer />
	</div>
  );

}

export default Products;