import React,{useEffect , useState} from "react";
import configData from "../../config.json";
import Header from "./Header.js";
import Footer from "./Footer.js";
import "../../styles/registrering/churnlogin.css";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import ProductService from "../../services/registrering/ProductService";
import { useNavigate  } from "react-router-dom";
import UserService from "../../services/registrering/UserService";
import AuthService from "../../services/registrering/AuthService";
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"


function ResetPassword() {
  const navigate = useNavigate();
  //const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND); 
  const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
  const msisdn = localStorage.getItem(configData.STORAGE_MSISDN);
  const productSlug = localStorage.getItem(configData.STORAGE_PRODUCT_TAG);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [productInfo, setproductInfo] = useState({});
  const formSchema = Yup.object().shape({
		username: Yup.string().required(configData.NUMBER_REQUIRED),
		smscode: Yup.string().required(configData.SMSCODE_REQUIRED),
		password: Yup.string().required(configData.PASSWORD_REQUIRED).min(configData.PASSWORD_MIN_LENGTH, configData.PASSWORD_SHORT),
		confirmpassword: Yup.string().required(configData.NOT_MATCHING_PASSWORDS).oneOf([Yup.ref("password")], configData.NOT_MATCHING_PASSWORDS),
  })
  const formOptions = {
    resolver: yupResolver(formSchema)
  }
  const {
    register,
    handleSubmit,
    formState
  } = useForm(formOptions);
  const {
    errors
  } = formState;

  

  const onSubmit = data => {
	  	const userData = {
        "mobile": "47"+data.username,
        "code": data.smscode,
        "password": data.password
	    }
	   
		UserService.verify(localStorage.getItem(configData.ACCESS_TOKEN), userData).then(
			(user) => {
        console.log("user", user);
        if(user.status===200){
          AuthService.login(userData.mobile, userData.password).then(
              (authUser) => {
                console.log("authUser", authUser);
                if(authUser.status===200){
                  //setErrorMessage("");
                  //localStorage.setItem(configData.EXISTING_USER, 'yes');
                  localStorage.setItem(process.env.REACT_APP_USER_ACCESS_TOKEN, authUser.data.access_token);
                  setSuccessMessage(configData.SUCCESSFULLY_LOGGEDIN);
                  navigate(configData.PAYMENT_PAGE);
                  
                } else {
                  // console.log('!200', user);
                  setSuccessMessage("");
                  setErrorMessage(configData.WRONG_LOGIN_CREDENTIALS);
                }
              }
            );
              //navigate(configData.PAYMENT_PAGE);
            }
				
			}
		);
	}

  useEffect(() => {
    if (productId === null) {
      navigate(configData.PRODUCT_PAGE);
    }
  })

  useEffect(() => {
    if (productSlug === "undefined" || productSlug === null) {
      navigate(configData.PRODUCT_PAGE);
    }
    ProductService.getProductByTag(productSlug).then(
      (product) => {
        //console.log(product);
        setproductInfo(product);
        localStorage.setItem(configData.PRODUCT_BACKGROUND, product.product_background);
      }
    );
  }, [productSlug, navigate])



  
  
  return (
  	<div id="churn-page-main-container" className="reg-product_background-dark">
      <Header colorFromParent="reg-primary-color-pallete" />
        <div className="reg-container reg-churnlogin-main" id="churn-page-container" >
          <div className="reg-row" id="churn-page-row-one">
            <div className="reg-col-12" id="churn-page-row-one-column">
              <div className="reg-well  reg-text-left"  id="churn-page-well-one">
                <a id="churn-page-tilbake" href={configData.PRODUCT_PAGE}>TILBAKE</a>
              </div>
            </div>
          </div>
          <div className="reg-row" id="churn-page-row-two">
            {productInfo ?
            <div className="reg-col-7" id="product-page-container-row-two-column-one">
              <div className="reg-well reg-text-center" id="product-page-container-row-two-well-one">
                <div className="reg-row" id="product-page-container-row-two-row-one">
                  
                </div>
                <div className="reg-row" id="product-page-container-row-two-row-two">
                  <div className="reg-col-12" id="product-page-container-row-two-row-two-column">
                    <div id="product-page-container-product-name-container">
                      <h5 id="product-page-container-product-name" className="reg-filson-pro-heavy reg-font-size-45 reg-font-color-pink">{productInfo.name}</h5>
                    </div>
                  </div>
                </div>
                <div className="reg-row" id="product-page-container-row-two-row-three">
                  <div className="reg-col-12" id="product-page-container-row-two-row-three-column">
                    <div className="reg-well reg-text-center" id="product-page-container-row-two-row-three-well">
                      <h4 id="product-page-container-row-two-row-three-substitle" className="reg-sofia-pro-reg-italic reg-font-size-45 reg-font-color-green desktop-only">{productInfo.subtitle}</h4>
                       <hr className="reg-green-line-separator" id="product-page-container-row-two-row-three-separator" />
                      <h4 id="product-page-container-row-two-row-three-mobilesubtitle" className="reg-secondary-color reg-filson-pro-heavy reg-font-size-25 reg-home-product-subtitle reg-obile-only reg-fm-mobfont-25">{productInfo.subtitle_mobile}</h4>                      
                    </div>
                  </div>
                </div>
                <div className="reg-row" id="product-page-container-row-two-row-four">
                  <div className="reg-col-12" id="product-page-container-row-two-row-four-column">
                    <div className="reg-well reg-text-center" id="product-page-container-row-two-row-four-well-one">
                      &nbsp;
                    </div>
                    <div className="reg-well reg-text-center" id="product-page-container-row-two-row-four-well-two">
                      <div className="reg-secondary-color " id="product-page-container-price-mnd">
                          <h5 id="product-page-container-price" className="reg-filson-pro-heavy reg-font-size-60 reg-font-color-green reg-fm-mobfont-45">{productInfo.price} kr</h5>
                          <h6 id="product-page-container-mnd" className="reg-filson-pro-heavy reg-font-size-35 reg-font-color-green reg-fm-mobfont-25">/mnd</h6>
                        </div>
                    </div>
                  </div>
                  
                </div>

                <div className="reg-row reg-desktop-only" id="product-page-container-row-two-row-five">
                  <div className="reg-col-12" id="product-page-container-row-two-row-five-column">
                    <div className="reg-well reg-text-left" id="product-page-container-row-two-row-five-well-one">
                      &nbsp;
                    </div>
                    <div className="reg-well reg-text-left reg-col-sm-offset-1 reg-secondary-color" id="product-page-container-row-two-row-five-well-two">
                      
                      <div className="reg-sofia-pro-reg reg-font-size-26 reg-font-color-green" id="product-page-container-streams">
                        <p>{productInfo.streams}</p>
                      </div>
                      <div  id="product-page-container-features-container">
                      {productInfo.features ? 
                        <>
                        {productInfo.features.included.map((feature, fkey) =>( 
                          <div id={"product-page-container-features-"+fkey+"-text-container"}  key={feature} className="reg-product-list-element reg-sofia-pro-reg reg-font-size-26 reg-font-color-green ">
                            <FontAwesomeIcon icon={faCircle} className="reg-font-size-16" id={"product-page-container-features-"+fkey+"-icon"}  />
                            <p id={"product-page-container-features-"+fkey+"-text"} >{feature.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}</p>
                            </div> 
                        ))}

                        {productInfo.features.not_included==null ? "" : 
                          
                          productInfo.features.not_included.map((feature, fkey) =>(
                            <>
                            <div id={"product-page-not_included-container-features-"+fkey+"-text"} key={feature} className="reg-product-list-element reg-sofia-pro-reg-italic reg-font-size-26 x-field ">
                              <FontAwesomeIcon id={"product-page-not_included-container-features-"+fkey+"-icon"} icon={faTimes} className="reg-font-size-16 reg-fontawesome-times reg-font-size-16" />
                              <p id={"product-page-not_included-container-features-"+fkey+"-text"}>{feature.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}</p>
                            </div> 
                            </> 
                          ))
                          
                        }
                        </>
                      : "" }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reg-row  reg-desktop-only" id="product-page-container-row-two-row-six">
                  <div className="reg-col-12" id="product-page-container-row-two-row-six-column">
                    <div className="reg-well reg-text-left" id="product-page-container-row-two-row-six-well-one">
                      &nbsp;
                    </div>
                    <div className="reg-well reg-text-center reg-secondary-color" id="product-page-container-row-two-row-six-well-two">
                      <h5 className="reg-text-left reg-col-xs-offset-1 reg-sofia-pro-reg reg-font-size-20 " id="product-page-container-description-container">
                      *Samtidig strømming betyr hvor mange personer som kan lytte samtidig, på hver sin enhet.<br /><br />**Opprett en ny konto for å prøve Fabel gratis i 4 uker. Gjelder kun nye kunder. Etter gratisperioden fornyes tjenesten automatisk for 79 kr/måneden.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : '' }
            <div className="reg-col-1" id="churn-page-row-two-column-two">
            &nbsp;
            </div>
            <div className="reg-col-4" id="churn-page-row-two-column-three">
              <div className="reg-well text-right" id="churn-page-row-two--column-three-well">
                <div className="reg-row" id="churn-page-row-two--column-three-row">
                  <div className="reg-col-12" id="churn-page-row-two--column-three-column">
                    <div className="reg-form-panel text-left" id="churn-page-form-panel">
                      <div align="center" id="churn-page-form-step-container">
                        <div className="reg-well reg-form-steps" id="churn-page-form-steps">
                          <div className="reg-step reg-step-active" id="churn-page-form-step-one">1</div>
                          <hr className="reg-step-separator" id="churn-page-form-step-sep-one" />
                          <div className="reg-step" id="churn-page-form-step-two">2</div>
                          <hr className="reg-step-separator" id="churn-page-form-step-sep-two" />
                          <div className="reg-step" id="churn-page-form-step-three">3</div>
                        </div>
                      </div>
                      <div className="reg-well" id="churn-page-form-well">
                        <div id="churn-page-form-text-one" className="reg-sub-title reg-sofia-pro-reg reg-font-size-12">Bruk koden du mottok på SMS til å logge inn nedenfor.
                        </div>
                        <form id="churn-page-form"  onSubmit={handleSubmit(onSubmit)}>
                        <div className="reg-gap" id="churn-page-container-main-form-gap-one"></div>
                          <div className="reg-input" id="churn-page-form-input-mobile">
                            <label id="churn-page-form-input-mobilenumber-label" className="reg-sofia-pro-reg reg-font-size-12">Fikk du ikke tilsendt kode? <a href={process.env.REACT_APP_FABEL_URL+"vilkar"}>Send kode på nytt</a> </label>
                            <input id="churn-page-form-input-mobilenumber" value={msisdn} name="username" type="tel" placeholder="Mobilnummer" {...register(configData.INPUT_PHONE, {required: true, maxLength: 8, minLength: 8, value: msisdn})} /> 
                            
                            {errors.username?.type === "required" && <p className="reg-error-message reg-sofia-pro-reg reg-font-size-12">Mobilnummer krevewws</p> } 
                            {errors.username?.type === "maxLength" && <p className="reg-error-message reg-sofia-pro-reg reg-font-size-12">Mobilnummeret er ugyldig</p> } 
                            {errors.username?.type === "minLength" && <p className="reg-error-message reg-sofia-pro-reg reg-font-size-12">Mobilnummeret er ugyldig</p> }
                          </div>
                          <div className="reg-gap" id="churn-page-container-main-form-gap"></div>
                          <div className="reg-input" id="churn-page-form-input-password-con">
                            <label id="churn-page-form-input-password-label"  className="reg-sofia-pro-reg reg-font-size-12">Skriv inn koden du mottol p&aring; fra 2280</label>
                            <input id="churn-page-form-input-password" name="smscode" type="number" {...register(configData.INPUT_SMSCODE)} />
                             
                          </div>
                          <div id="churn-page-form-error-password" className="reg-error-message reg-sofia-pro-reg reg-font-size-12">{errors.smscode?.message}</div>
                          <div className="reg-gap" id="churn-page-container-main-form-gap"></div>
                          <div className="reg-input" id="churn-page-form-input-password-con">
                            <label id="churn-page-form-input-password-label"  className="reg-sofia-pro-reg reg-font-size-12">Lag nytt passord</label>
                            <input id="churn-page-form-input-password" name={configData.INPUT_PASSWORD} type="password" {...register(configData.INPUT_PASSWORD)} />
                             
                          </div>
                          <div id="churn-page-form-error-password" className="reg-error-message reg-sofia-pro-reg reg-font-size-12">{errors.password?.message}</div>
                          <div className="reg-gap" id="churn-page-container-main-form-gap"></div>
                          <div className="reg-input" id="churn-page-form-input-password-con">
                            <label id="churn-page-form-input-password-label"  className="reg-sofia-pro-reg reg-font-size-12">Gjenta passord</label>
                            <input id="churn-page-form-input-password" name={configData.INPUT_CONFIRM_PASSWORD} type="password" {...register(configData.INPUT_CONFIRM_PASSWORD)} />
                             
                          </div>
                          <div id="churn-page-form-error-password" className="reg-error-message reg-sofia-pro-reg font-size-12">{errors.confirmpassword?.message}</div>
                 
                          
                          <div className="reg-gap" id="churn-page-form-gap"></div>
                          {errorMessage ? <p id="churn-page-form-error-message" className="reg-error-message reg-sofia-pro-reg font-size-12">{errorMessage}</p> : ""}
                          {successMessage ? <p className="reg-success-message">{successMessage}</p> : ""} 
                          <div className="reg-submit-button  " id="churn-page-form-submit-button">
                            <button  id="churn-page-form-button" type="submit" className="reg-sofia-pro-bold reg-churn-login-submit-button reg-font-size-20">Logg inn</button>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {productInfo.product_data ?
          <>
          <div className="reg-row  mobile-only" id="churn-page-mobile-section">
            <div className="reg-col-12" id="churn-page-mobile-section-column">
              <div className="reg-well reg-text-left" id="churn-page-mobile-section-well">
                &nbsp;
              </div>
              <div className="reg-well reg-text-left reg-col-sm-offset-1 reg-secondary-color" id="churn-page-mobile-section-well-two">
                <div className="reg-product-list-element reg-sofia-pro-reg reg-font-size-16 " id="churn-page-mobile-stream">
                <p id="churn-page-mobile-stream-text">{productInfo.product_data.streams}</p>
                </div>
                <div id="churn-page-container-features-mobile">
                  {productInfo.product_data.features ? productInfo.product_data.features.map((feature, fkey) =>( 
                  <div id={"churn-page-container-feature-list-"+fkey} key={feature.text}> {feature.status===true ? <div id={"churn-page-container-feature-text-mobile-"+fkey} key={feature.text} className="product-list-element sofia-pro-reg font-size-16 font-color-green">
                    <FontAwesomeIcon id={"churn-page-container-feature-icon-one-mobile-"+fkey} icon={faCircle} className="reg-fontawesome-circle" />
                    <p id={"churn-page-container-feature-text-m-"+fkey}>{feature.text.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}</p>
                  </div> : <div id={"churn-page-container-feature-text-mobile-"+fkey} key={feature.text} className="reg-product-list-element reg-x-field reg-sofia-pro-reg-italic reg-font-size-16  ">
                    <FontAwesomeIcon id={"churn-page-container-feature-icon-one-mobile-"+fkey} icon={faTimes} className="reg-fontawesome-times" />
                    <p id={"churn-page-container-feature-text-m-"+fkey}>{feature.text.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}</p>
                  </div> } </div> )) : ""}
                </div>
                
              </div>
            </div>
          </div>
          <div className="reg-row  reg-mobile-only" id="churn-page-mobile-row-one">
            <div className="reg-col-12" id="churn-page-mobile-row-one-column">
              <div className="reg-well text-left" id="churn-page-mobile-row-one-well">
                &nbsp;
              </div>
              <div className="reg-well reg-text-center reg-secondary-color" id="churn-page-mobile-row-one-description">
                <h5 id="churn-page-mobile-row-one-description-text" className="reg-text-left reg-col-xs-offset-1 reg-sofia-pro-reg reg-font-size-14"> 
                  {productInfo.product_data.description ? productInfo.product_data.description.text1.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS)) : ""}
                  <br />
                  <br /> 
                  {productInfo.product_data.description ? productInfo.product_data.description.text2.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS)) : ""}
                </h5>
              </div>
            </div>
          </div>
          </>
          : '' }
          <div className="reg-row" id="churn-page-mobile-row-two">
            <div className="reg-col-12" id="churn-page-mobile-row-two-column">
              <div className="reg-gap" id="churn-page-mobile-row-two-column-gap"></div>
            </div>
          </div>
        </div>

      <Footer />
    </div>
  	)
}
export default ResetPassword;