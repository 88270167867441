import axios from "axios";
class ProductService {
    
    
    getAllProducts() {
        const options = {
            method: "GET",
            
            //url:  API_URL + "/products"
            url:  process.env.REACT_APP_API_SERVER_URL+"v2/products/base"
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    
    

}

export default new ProductService();