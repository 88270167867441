import axios from 'axios';

const API_URL = process.env.REACT_APP_API_SERVER_URL
//const API_URL = "http://127.0.0.1:8000/"


//service for all subscription/user purposes
class UserService {
    getUserFromToken(access_token) {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token
            },
            url: API_URL + 'v2/user/'
        }
        return axios(options)
            .then(response => {
                return response.data
            });
    }

    changeUser(access_token, user) {
        
        const options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user',
            data: JSON.stringify(user)
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getReceiptList(access_token) {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription/receipt',
        }
        return axios(options)
        .then(response => {
            return response.data;
        });
    }

    changePassword(mobile, code, password) {
        
        const options = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/registeruser/',
            data: JSON.stringify({mobile: mobile, code: code, password: password})
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getSubscription(access_token) {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription',
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getBooks(access_token, fabel_id) {
  
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/bokbasen/password-credentials/bookshelf'
        }
        
        return axios(options)
            .then(response => {
                return response.data;
            })
    }

    getOneBookCredits(access_token){
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription/one-book',
            
        }
        
        return axios(options)
        .then(response => {
            return response.data;
        });
    }

    downloadBook(access_token, link) {
        
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/bokbasen/password-credentials/download-url',
            data: JSON.stringify({url: link})
        }
        return axios(options)
            .then(response => {
                return response.data
            })

    }

    stripeSetupIntent(access_token) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/payment/stripe/intent',
        }
        return axios(options)
            .then(response => {
                return response.data
            });
    }

    stripePaymentMethod(access_token, reference) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/payment/paymentmethods/',
            data: JSON.stringify({reference: reference})
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    vippsIntent (access_token, vippsData){
        const options = {
            method: "POST",
            data: {"product_id": vippsData.product_id, "campaign_code": vippsData.campaign_code, "redirect_url": vippsData.redirect_url},
            //data: vippsData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: API_URL + "v2/payment/vipps/intent"
        }
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        })
    }

    vippsSubscription(access_token, vippsData) {
        //return access_token;
        const options = {
            method: "POST",
            data: {"product_id": vippsData.product_id, "utm_campaign": vippsData.utm_campaign, "reference": vippsData.reference},
            //data: vippsData,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: API_URL + "v2/payment/vipps/subscription"
        }
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response;
        })

        
    }

    changeSubscription(access_token, product_id) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription/',
            data: JSON.stringify({product_id: product_id})
        }
        return axios(options)
            .then(response => {
                return response.data;
            }).then(error => { console.log(error)})
    }

    changeStripeProduct(access_token, product_id) {
        const options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/payment/stripe/subscription',
            data: JSON.stringify({product_id: product_id})
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    changeStrexProduct(access_token, product_id) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/payment/strex/subscription',
            data: JSON.stringify({product_id: product_id})
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    redeemGiftcard(access_token, coupon) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/coupon/redemption/',
            data: JSON.stringify({coupon: coupon})
        }

        return axios(options)
            .then(response => {
                return response
            })
    }

    stopSubscription(access_token, comment) {
        const options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            //url: API_URL + 'user/subscription/',
            url: API_URL + 'v2/user/subscription/cancel',
            /*data: JSON.stringify({
                state: "6",
                comment: comment
            })*/
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    resumeSubscription(access_token) {
        const options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            //url: API_URL + 'user/subscription/',
            url: API_URL + 'v2/user/subscription/resume',
            /*data: JSON.stringify({
                state: "6",
                comment: comment
            })*/
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }



    activateSubscription(access_token) {
        const options = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription',
            data: JSON.stringify({
                state: "3"
            })
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }


    subRenewalPOST(access_token, sub_id, product_id) {
        const options = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription/renew/',
            data: JSON.stringify({
                sub_id: sub_id,
                product_id: product_id
            })
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    subRenewalGET(access_token) {
        const options = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/subscription/renew/',
            data: JSON.stringify({
                sub_id: "",
                produckt_id: ""
            })
        }
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    newSmsCode(mobile) {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: API_URL + 'v2/user/reset-password',
            data: JSON.stringify({
                mobile: mobile
            })
        }
        return axios(options)
        .then(response => {
            return response
        })
    }

}

export default new UserService();