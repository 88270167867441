import {NavLink } from 'react-router-dom';
function Stopped() {
document.body.style.backgroundColor = '#002B1D';
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row no-padding">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                                <h1>Min konto</h1>
                                <p>
                                    Martine Hansen<br />
                                    Mobilnummer: 90 64 99 23<br />
                                    E-post: martinebratlihansen@gmail.com
                                </p>
                                
                                <p>
                                    <span>Abonnementet er stoppet</span><br />
                                    Utl&oslash;per: 23.09.2023
                                </p>
                                
                                
                            </div>
                            
                            <div className="col-12 col-sm-12 col-lg-10 fm-accordion-area d-sm-block d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn fm-accordion-btn-active ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                <div className="d-lg-none col-12 col-sm-12 col-lg-12 fm-accordion-content-mobile">
                                    <p className="p1">ABONNEMENTET ER STOPPET</p>
                                    <p className="p2">Fabel Basis: 149 kr/mnd</p>
                                    <p className="p3">1 profil kan lytte om gangen<br />Tilgang til alle lydb&oslash;ker, bortsett fra lydb&osalsh;ker for barn og ungdom</p>
                                    
                                    <p className="p4">NESTE BETALINGSDATO<br /><strong>23.09.2023</strong></p>
                                    
                                    <NavLink className="fm-bott-button" to={"/changesub"} >Administrer abonnement</NavLink>
                                </div>
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjopshistorikk"}>
                                    <div className=" col-sm-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                
                            </div>
                            
                            

                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-12" style={{paddingTop: '80px'}}>
                                    <h1>Abonnement</h1>
                                    <p>ABONNEMENTET ER STOPPET</p>
                                    <p>Husk at du fremdeles har en kostnadsfri Fabel-konto som gir deg tilgang til et utvalg av innholdet vårt og alle funksjonene.</p>
                                    <p>&nbsp;</p>
                                    <h3 className="strike greyout">Fabel Basis: 149 kr/mnd </h3>
                                    <p className="greyout">1 profil kan lytte om gangen</p>
                                    <p className="greyout">Tilgang til alle lydb&oslash;ker, bortsett fra lydb&oslash;ker for barn og ungdom</p>
                                    <p>&nbsp;</p>
                                    <p>UTL&Oslash;PER<br /><strong>23.09.2023</strong></p>
                                    <div className=" row"  align="center"  >
                                        <div className="fm-bott-button col-6" align="center" >
                                        <NavLink  to={process.env.REACT_APP_MINKONTO_URL} >Administrer abonnement</NavLink>
                                        </div>
                                        
                                    </div>
                                    <br/ >
                                    <p className="smaller">&Oslash;nsker du ikke &aring; ha en kostnadsfri Fabel-konto?
                                    <br/ >
                                    Ta kontakt med <u><b>kundeservice@fabel.no</b></u>. Vær klar over at du da ikke<br/ > f&aring;r tilgang til kostnadsfritt innhold i appen.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                
            </div>
        </div>
    )
}
export default Stopped;
  