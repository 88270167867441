import {NavLink } from 'react-router-dom';
function NotFound() {
    document.body.style.backgroundColor = '#E7D0FF';
    return (
        <div>
            <div className="container-fluid">
              <div className="container">
              <br /><br /><br />
              </div>
              <div className="container">
                    <div className="row" align="center">
                        <div className="col-lg-2 col-sm-12" align="center">
                        </div>
                        <div className="col-lg-8 col-sm-12" align="center">
                        <h1 className="fm-h1">Side ikke funnet</h1>
                        </div>
                        <div className="col-lg-2 col-sm-12" align="center">
                        </div>
                  </div>
              </div>   
              <div className="container">
                  <div className="row" >
                      <div className="col-12"  align="center">
                          
                          <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL} >Tilbake til Min konto</NavLink>
                          
                      </div>
                  </div>
              </div>   
            </div>

        </div>
    )
}
export default NotFound;
  