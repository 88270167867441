import axios from "axios";
import configData from "../../config.json";
import qs from 'qs';
const API_URL = configData.API_SERVER_URL;


class UserService {
    
    register(access_token, user_data) {
        console.log(user_data);
        const options = {
            method: "POST",
            data: {"mobile": user_data.mobile, "email": user_data.email, "newsletter": user_data.newsletter},
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "text/plain"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/registeruser/"
        }
        
        return axios(options)
        .then(response => {
            return response.data;
        }).catch(function (error) { 
            return error.response.status;
        })
        
    }
    
    verify(access_token, user_data) {
        
        const options = {
            method: "PATCH",
            data: {"mobile": user_data.mobile, "code": user_data.code, "password": user_data.password},
            headers: {
                //"Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/registeruser/"
        }
        
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        })
        
    }

    
    getUserSubscription(access_token){
       const options = {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "text/plain"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/user/subscription"
        }
        
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        }) 
    }

    forgotPassword(access_token, user_data){
        console.log(user_data);
        const options = {
            method: "POST",
            data: {"mobile": user_data},
            headers: {
                //"Authorization": "Bearer " + access_token,
                "Content-Type": "text/plain"
            },
            url: process.env.REACT_APP_API_SERVER_URL + "v2/user/reset-password"
        }
        
        return axios(options)
        .then(response => {
            return response.status;
        }).catch(function (error) { 
            return error.response.status;
        })


        
    }

    getUserByPhone(phone) {
        const options = {
            method: "GET",
            url: API_URL + "/user/" +phone
        }
        return axios(options)
        .then(response => {
            return response.data;
        });
    }

    
    

}

export default new UserService();