import React,{useEffect} from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import configData from "../../config.json";
import { useNavigate  } from "react-router-dom";
import "../../styles/registrering/registercomplete.css";

function RegisterComplete() {
	const navigate = useNavigate();
	//const productSlug = localStorage.getItem(configData.STORAGE_PRODUCT_TAG);
	const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
	const orderPrice = localStorage.getItem(configData.STORAGE_ORDER_PRICE);
	useEffect(() => {
		if(productId===null){
		  navigate(configData.PRODUCT_PAGE);
		}
	})
  return (
  	<div id="reg-complete-page-main-container" className="product_background-dark">
	  <Header colorFromParent="primary-color-pallete"  />
	  <div className="container" id="reg-complete-page-container">
	  	<div className="row" id="reg-complete-page-row">
	  		<div className="col-12"  id="reg-complete-page-row-column">
	  			<div className="regular-complete-page " id="reg-complete-page-template">
	  				<h1 id="reg-complete-template-title" className="title complete-page-title filson-pro-heavy font-size-60 font-color-pink">Velkommen til Fabel!</h1>
				    <span id="reg-complete-template-subline" className="subtitle complete-page-subtitle filson-pro-reg-italic font-size-40">Vi er glade for å ha deg ombord!</span>
				    <hr />
				    <div className="well step-heading  " id="reg-complete-template-well">
				      
				          <span id="reg-complete-template-stepheading" className="step-heading-text complete-page-step-heading font-size-40 fm-mobfont-25">Her er tre trinn for å komme i gang:</span>
				        </div>
				        <div className="regular-step  font-size-26 fm-mobfont-16" id="reg-complete-template-steps-con">
				          <div className="substep font-size-26 fm-mobfont-16" id="reg-complete-template-steps">
				            <span className="bold-font fm-mobfont-16" id="reg-complete-template-step">1. Last ned Fabel-appen.</span>
				            <br /> Du finner den i App store (hvis du har iPhone/iPad), i Google Play eller Huawei AppGallery
				          </div>
				        </div>
				        <div className="app-icon hidden-sm" id="reg-complete-desktop-applications">
				          <div className="col-6" id="reg-complete-template-column-one">
				            <a id="reg-complete-template-app-icon-anchor" href="https://apps.apple.com/no/app/fabel/id1174300811">
				              <img id="reg-complete-template-app-icon-img" alt="app-store-icon" width="220" src="/img/apple-app-store-icon.png" />
				            </a>
				          </div>
				          <div className="col-6"  id="reg-complete-template-column-two">
				            <a id="reg-complete-template-play-store-anchor" href="https://play.google.com/store/apps/details?id=no.fabel">
				              <img id="reg-complete-template-play-store-img" alt="play-store-icon"  width="220"  src="/img/google-play-store-icon.png" />
				            </a>
				          </div>
				        </div>
				        <div className="hidden-lg" id="reg-complete-mobile-applications">
				          <div className="col-12" id="reg-complete-mobile-column-one">
				            <a id="reg-complete-mobile-app-icon-anchor" href="https://apps.apple.com/no/app/fabel/id1174300811">
				              <div id="reg-complete-mobile-app-icon-appstore"  className="app-icon-appstore">
				                <label id="reg-complete-mobile-app-icon-label">AppStore</label>
				              </div>
				            </a>
				          </div>
				          <div className="clear-both" id="reg-complete-mobile-clb"><br /></div>
				          <div className="col-12" id="reg-complete-mobile-column-two">
				            <a id="reg-complete-mobile-play-store-anchor" href="https://play.google.com/store/apps/details?id=no.fabel">
				              <div id="reg-complete-mobile-play-store-store" className="app-icon-appstore">
				                <label id="reg-complete-mobile-play-store-label">GooglePlay</label>
				              </div>
				            </a>
				          </div>
				        </div>
				        <div className="regular-step" id="reg-complete-steps-one">
				          <div className="substep font-size-26 fm-mobfont-16" id="reg-complete-steps-one-container">
				            <span id="reg-complete-steps-one-text" className="bold-font fm-mobfont-16">2. Logg deg inn i appen.</span>
				            <br /> Bruk telefonnummeret ditt og passordet du lagde da du registrerte deg.
				          </div>
				        </div>
				        <div className="regular-step" id="reg-complete-steps-two">
				          <div className="substep font-size-26 fm-mobfont-16" id="reg-complete-steps-two-container">
				            <span  id="reg-complete-steps-two-text"  className="bold-font fm-mobfont-16">3. Finn lydboka du vil høre! God fornøyelse!</span>
				            
				          </div>
				        </div>
				        
	  				        		
	  			</div>
	  			<div className="disclaimer font-size-26" id="reg-complete-page-disclaimer">
		        	<div className="substep font-size-26 fm-mobfont-14"> Greit å vite: Etter gratisperioden koster tjenesten {orderPrice} kr/mnd. <br /> Du kan gjøre endringer på ditt abonnement ved å logge inn på <a className="bold-font" href={configData.FABEL_MINSIDE_URL}>minside.fabel.no</a>
		        </div>
		      </div>
	  		</div>
	  	</div>
	  </div>
	 
	  <Footer />
	</div>
  	)
}
export default RegisterComplete;