import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import configData from "../../config.json";
function CampaignProduct(match) {
	

return (
    <>
    <div className="reg-signup-left">
      <div className="reg-camp-prd-ttl">
      {match.productinfo.selected_product_name}
      </div>
      <hr className="reg-part-fabel-hr" />
      <div className="reg-camp-prd-subttl">
      {match.productinfo.selected_product_subtitle}
      </div>
      <div class="part-prd-slogan">
      <p>{match.productinfo.slogan}</p>
      </div>
      <div class="camp-prd-info">
      <p>{match.productinfo.campaign_info ? localStorage.getItem(configData.UTM_WEEKS)==="" || localStorage.getItem(configData.UTM_WEEKS)===null ? match.productinfo.campaign_info :  match.productinfo.campaign_info.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS)) : null}</p>
      </div>
    </div>
    <div className="reg-camp-features" id="product-page-container-row-two-row-five">
      <div className="reg-col-12" id="product-page-container-row-two-row-five-column">
          
          
            {match.productinfo.features ? 
            <>
            {match.productinfo.features.included.map((feature, fkey) =>( 
                <div id={"product-page-container-features-"+fkey+"-text-container"}  key={"0-"+fkey} className="reg-product-list-element reg-camp-product-list-element  reg-sofia-pro-reg font-size-18 font-color-green ">
                <FontAwesomeIcon icon={faCircle} className="reg-font-size-10" id={"product-page-container-features-"+fkey+"-icon"}  />
                <p id={"product-page-container-features-"+fkey+"-text"} >{feature}</p>
                </div> 
            ))}

            {match.productinfo.features.not_included==null ? "" : 
              
              match.productinfo.features.not_included.map((feature, fkey) =>(
                <>
                <div id={"product-page-not_included-container-features-"+fkey+"-text"} key={"1-"+fkey} className="product-list-element  camp-product-list-element  not-included-feature sofia-pro-reg font-size-18 x-field ">
                  <FontAwesomeIcon id={"product-page-not_included-container-features-"+fkey+"-icon"} icon={faTimes} className="font-size-16 fontawesome-times font-size-16" />
                  <p id={"product-page-not_included-container-features-"+fkey+"-text"}>{feature}</p>
                </div> 
                </> 
              ))
              
            }
            </>
          : "" }
      </div>
    </div>
    
    </>
	);

}

export default CampaignProduct;