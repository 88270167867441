import axios from "axios";
import qs from 'qs';

//service for all authentication purposes
class AuthService {

    
    generateAccessToken(){
        const data = {
            grant_type: "client_credentials",
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data),
            url: process.env.REACT_APP_API_SERVER_URL + "v2/token/",
        }  
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    generateUserToken(username, password){
        const data = {
            grant_type: "password",
            username: username,
            password: password,
            client_id: process.env.REACT_APP_PS_CLIENT_ID,
            client_secret: process.env.REACT_APP_PS_CLIENT_SECRET,
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data),
            url: process.env.REACT_APP_API_SERVER_URL + "v2/token/",
        }  
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    login(username, password) {
        const data = {
            grant_type: "password",
            username: username,
            password: password,
            client_id: process.env.REACT_APP_PS_CLIENT_ID,
            client_secret: process.env.REACT_APP_PS_CLIENT_SECRET,
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data),
            url: process.env.REACT_APP_API_SERVER_URL + "v2/token/",
        }  
        return axios(options)
        .then(response => {
            return response;
        }).catch(function (error) { 
            return error.response.status;
        })    
        
    }

    

}


export default new AuthService();