import Header from "./Header.js";
import configData from "../../config.json";
import React,{useState, useEffect} from "react";
import UserService from "../../services/registrering/UserService";
import { useNavigate  } from "react-router-dom";
import { useForm } from "react-hook-form";
import Footer from "./Footer.js";
import "../../styles/registrering/forgot.css";

function RegForgotPassword() {
	const navigate = useNavigate();
	const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
	const msisdn = localStorage.getItem(configData.STORAGE_MSISDN);
	//  const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND); 
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const {
		register,
		handleSubmit,
		formState: {
			errors
		}
	} = useForm();
	const onSubmit = data => {
		//localStorage.setItem(configData.STORAGE_MSISDN, data.msisdn);
		UserService.forgotPassword(localStorage.getItem(configData.ACCESS_TOKEN), '47'+data.username).then(
			(user) => {
				console.log("user", user);
				if (user === 200) {
					setErrorMessage("");
					//setSuccessMessage(configData.FORGOT_PASSWORD_SENT_MESSAGE);
					navigate(configData.RESET_PASSWORD_PAGE);
				} else if (user === configData.PHONE_NOT_REGISTERED) {
					setSuccessMessage("");
					setErrorMessage(configData.PHONE_NOT_REGISTERED);
				}
			
			}
		);
	}

	useEffect(() => {
		if (productId === null) {
			navigate(configData.PRODUCT_PAGE);
		}
	})
	
	

  return (
  	<div className="product_background-dark">
	  <Header colorFromParent="primary-color" />
	  <main className="bekreft-main">
	    <div className="container-forgot">
	      <div className="right">
	        <div className="forgot-form">
	          <div className="tp-txt">
	            <p>Endre passord</p>
	          </div>
	          <div className="vrfy-send-sms" id="signup_step_two_sms_btn">
	            <p>Skriv inn telefonnummeret du bruker til å logge inn i Fabel så sender vi deg instruksjoner for å lage et nytt passord.</p>
	          </div>
	          <form onSubmit={handleSubmit(onSubmit)}>
	            <div className="input">
	              <label>Mobilnummer </label>
	              	{msisdn ? (
	              		<>
	              		<input type="text" name="username" value={msisdn} placeholder="Mobilnummer" {...register(configData.INPUT_PHONE, {required: true, maxLength: 8, minLength: 8})} /> {errors.msisdn?.type === "required" && <p className="error-message">{configData.NUMBER_REQUIRED}</p>} {errors.msisdn?.type === "maxLength" && <p className="error-message">{configData.INVALID_NUMBER}</p>} {errors.msisdn?.type === "minLength" && <p className="error-message">{configData.INVALID_NUMBER}</p>}
						</>
	              		)

	              	: (
	              	<>
	              		<input type="text" name="username" placeholder="Mobilnummer" {...register(configData.INPUT_PHONE, {required: true, maxLength: 8, minLength: 8})} /> {errors.msisdn?.type === "required" && <p className="error-message">{configData.NUMBER_REQUIRED}</p>} {errors.msisdn?.type === "maxLength" && <p className="error-message">{configData.INVALID_NUMBER}</p>} {errors.msisdn?.type === "minLength" && <p className="error-message">{configData.INVALID_NUMBER}</p>}
						</>
	              	)
					}
	            </div> {errorMessage ? <p className="error-message">{errorMessage}</p> : ""} {successMessage ? <p className="success-message">{successMessage}</p> : ""} <div className="submit-button" id="signup_step_two_submit_btn">
	              <button type="submit" className=" sofia-pro-bold font-size-20  btn-main">Fortsett</button>
	            </div>
	          </form>
	        </div>
	      </div>
	    </div>
	  </main>
	  <Footer />
	</div>
  	)
}
export default RegForgotPassword;