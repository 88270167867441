import { useForm } from "react-hook-form";
import React,{useEffect , useState} from "react";
import configData from "../../config.json";
import { yupResolver } from "@hookform/resolvers/yup"
import AuthService from "../../services/registrering/AuthService";
import UserService from "../../services/registrering/UserService";
import { useNavigate  } from "react-router-dom";
import * as Yup from "yup"
function SMSForm() {
    const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  //const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND);  
  const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);  
  const formSchema = Yup.object().shape({
    sms1: Yup.string().required(configData.VERIFICATION_CODE_REQUIRED).max(1),
    password: Yup.string().required(configData.PASSWORD_REQUIRED).min(configData.PASSWORD_MIN_LENGTH, configData.PASSWORD_SHORT),
    confirmpassword: Yup.string().required(configData.NOT_MATCHING_PASSWORDS).oneOf([Yup.ref("password")], configData.NOT_MATCHING_PASSWORDS),
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors } = formState
  //let data;
  const onSubmit = formdata => {
    const smscode = document.getElementById('code-1').value+document.getElementById('code-2').value+document.getElementById('code-3').value+document.getElementById('code-4').value ; //formdata.sms1+formdata.sms2+formdata.sms3+formdata.sms4;
    const password = formdata.password;
    const msisdn = localStorage.getItem(configData.STORAGE_MSISDN);

    //const email = localStorage.getItem(configData.STORAGE_EMAIL);
    const userdata = {
      "code" : smscode,
      "mobile" : "47"+msisdn,
      "password" : password
    }
    //console.log(userdata, data);
    UserService.verify(localStorage.getItem(configData.ACCESS_TOKEN), userdata).then(
      (user) => {
        console.log("user", user);
        if(user.status===200){
          AuthService.login(userdata.mobile, userdata.password).then(
              (authUser) => {
                console.log("authUser", authUser);
                if(authUser.status===200){
                  //setErrorMessage("");
                  //localStorage.setItem(configData.EXISTING_USER, 'yes');
                  localStorage.setItem(process.env.REACT_APP_USER_ACCESS_TOKEN, authUser.data.access_token);
                  setSuccessMessage(configData.SUCCESSFULLY_LOGGEDIN);
                  navigate(configData.PAYMENT_PAGE);
                  
                } else {
                  // console.log('!200', user);
                  setSuccessMessage("");
                  setErrorMessage(configData.WRONG_LOGIN_CREDENTIALS);
                }
              }
            );
              //navigate(configData.PAYMENT_PAGE);
            } else {
              document.getElementById('confirm-page-main-form-error-four').style.display = 'block';
              //navigate(configData.PAYMENT_PAGE);
            }
        //navigate(configData.PAYMENT_PAGE);
        ////console.log(user);
        /*if(user.success===configData.USER_VERIFY_MESSAGE){
          navigate(configData.PAYMENT_PAGE);
        }*/
      }
    );
  } 

  useEffect(() => {
    if(productId===null){
      navigate(configData.PRODUCT_PAGE);
    }

    let in1 = document.getElementById('code-1'),
        ins = document.querySelectorAll('input[type="number"]'),
       splitNumber = function(e) {
        let data = e.data || e.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
        if ( ! data ) return; // Shouldn't happen, just in case.
        if ( data.length === 1 ) return; // Here is a normal behavior, not a paste action.
        
        popuNext(e.target, data);
        //for (i = 0; i < data.length; i++ ) { ins[i].value = data[i]; }
      },
      popuNext = function(el, data) {
        el.value = data[0]; // Apply first item to first input

        data = data.substring(1); // remove the first char.
        if ( el.nextElementSibling && data.length ) {
          // Do the same with the next element and next data
          
          popuNext(el.nextElementSibling, data);

        }
      };
    ins.forEach(function(input) {
      /**
       * Control on keyup to catch what the user intent to do.
       * I could have check for numeric key only here, but I didn't.
       */
      input.addEventListener('keyup', function(e){
        // Break if Shift, Tab, CMD, Option, Control.
        if (e.keyCode === 16 || e.keyCode === 9 || e.keyCode === 224 || e.keyCode === 18 || e.keyCode === 17) {
           return;
        }
        if ( e.target.value.length > 1 ) {
          splitNumber(e);

        }
        // On Backspace or left arrow, go to the previous field.
        if ( (e.keyCode === 8 || e.keyCode === 37) && this.previousElementSibling && this.previousElementSibling.tagName === "INPUT" ) {
          this.previousElementSibling.select();
        } else if (e.keyCode !== 8 && this.nextElementSibling) {
          
          
          this.nextElementSibling.select();

        }
        
        // If the target is populated to quickly, value length can be > 1
        
      });
      
      /**
       * Better control on Focus
       * - don't allow focus on other field if the first one is empty
       * - don't allow focus on field if the previous one if empty (debatable)
       * - get the focus on the first empty field
       */
      input.addEventListener('focus', function(e) {
        // If the focus element is the first one, do nothing
        if ( this === in1 ) return;
        
        // If value of input 1 is empty, focus it.
        if ( in1.value === '' ) {

          in1.focus();
        } 

        //
        // If value of a previous input is empty, focus it.
        // To remove if you don't wanna force user respecting the fields order.
        if ( this.previousElementSibling.value === '' ) {

          this.previousElementSibling.focus();
        }
      });
    });
    in1.addEventListener('input', splitNumber);


  })

return (

	<div className="reg-sms-form-panel reg-text-left" id="product-page-container-form">
                      <div align="center" id="product-page-container-form-steps-container">
                        <div className=" reg-form-steps" id="product-page-container-form-steps">
                          <div className="reg-step" id="product-page-container-form-step-one">1</div>
                          <hr className="reg-step-separator" id="product-page-container-form-step-sep1" />
                          <div className="reg-step step-active" id="product-page-container-form-step-two">2</div>
                          <hr className="reg-step-separator" id="product-page-container-form-step-sep2" />
                          <div className="reg-step" id="product-page-container-form-step-three">3</div>
                        </div>
                      </div>
                      <div className="reg-well" id="product-page-container-form-well">
                          
                        <form id="product-page-container-main-form"  onSubmit={handleSubmit(onSubmit)}>
                          <div  id="product-page-container-main-form-well-one">
                          <br />
                          </div>

                          <div className=" reg-register-inside reg-sofia-pro-reg" id="sms-page-container-main-form-well-two">
                            <div className="reg-sofia-pro-bold font-size-16 text-center" id="confirm-page-form-tagline">
                              <p id="confirm-page-form-taglinep">Vi har sendt en SMS med en kode for å verifisere ditt mobilnummer</p>
                            </div> 

                            <div id="confirm-page-main-form-send-code">
                              <p id="confirm-page-main-form-send-code-text" className="reg-sofia-pro-reg reg-font-size-16">Har du ikke mottatt en kode?<br />
                              <a href="http://35.159.1.222/products" className="font-size-16"> Klikk her for å få sendt en ny</a></p>
                          
                            </div>
                            <br />
                            <div className="reg-verify-block" id="confirm-page-main-form-verify-block">
                                <div className="reg-verify-code reg-sofia-pro-reg" id="confirm-page-main-form-verify-code">
                                  <label id="confirm-page-main-form-verify-label" className="reg-sofia-pro-reg reg-font-size-16">Verifiseringskode</label>
                                  <div id="confirm-page-main-form-verify-inputs">
                                    <input className="reg-confirm-code-text" type="number" pattern="[0-9]*" min="0" max="9" maxLength="1" inputtype="numeric"  id="code-1"  name={configData.INPUT_SMS1} {...register(configData.INPUT_SMS1)} />
                                    <input className="reg-confirm-code-text" type="number" pattern="[0-9]*" min="0" max="9" maxLength="1" inputtype="numeric"   id="code-2"  name={configData.INPUT_SMS2} {...register(configData.INPUT_SMS2)}/>
                                    <input className="reg-confirm-code-text" type="number" pattern="[0-9]*" min="0" max="9" maxLength="1" inputtype="numeric"  id="code-3"  name={configData.INPUT_SMS3} {...register(configData.INPUT_SMS3)}/>
                                    <input className="reg-confirm-code-text" type="number" pattern="[0-9]*" min="0" max="9" maxLength="1" inputtype="numeric"   id="code-4"  name={configData.INPUT_SMS4} {...register(configData.INPUT_SMS4)}/>
                                  </div>
                          
                                  </div>
                                  <div id="confirm-page-main-form-error-one" className="reg-error-message reg-sofia-pro-reg reg-font-size-12">{errors.sms1?.message}</div>
                            </div>

                            <div className="reg-confirm-input reg-sofia-pro-reg" id="confirm-page-main-form-password-field">
                            <label id="confirm-page-main-form-password-label" htmlFor=""  className="reg-sofia-pro-reg reg-font-size-14">Lag et passord </label>
                            <input id="confirm-page-main-form-password-input" className="reg-sofia-pro-reg reg-font-size-12" name={configData.INPUT_PASSWORD} type="password" {...register(configData.INPUT_PASSWORD)} />
                          </div>
                          <div id="confirm-page-main-form-error-two" className="reg-error-message reg-sofia-pro-reg font-size-12">{errors.password?.message}</div>
                          <div className="reg-well" id="confirm-page-main-form-well-three">
                          </div>

                          <div className="reg-confirm-input reg-sofia-pro-reg" id="confirm-page-main-form-cpassword-field">
                            <label id="confirm-page-main-form-cpassword-label" htmlFor=""  className="reg-sofia-pro-reg reg-font-size-14">Gjenta passord </label>
                            <input id="confirm-page-main-form-cpassword-input" className="reg-sofia-pro-reg reg-font-size-12" name={configData.INPUT_CONFIRM_PASSWORD} type="password" {...register(configData.INPUT_CONFIRM_PASSWORD)} />
                          </div>

                          <div id="confirm-page-main-form-error-three" className="reg-error-message reg-sofia-pro-reg reg-font-size-12">
                          {errors.confirmpassword?.message}</div>
                          <div id="confirm-page-main-form-error-four" className="reg-error-message reg-sofia-pro-reg reg-font-size-12">
                          You entered wrong/invalid sms code
                          </div>
                          <div className="reg-confirm-submit-button" id="signup_step_two_submit_btn">
                            <button type="submit"  id="confirm-page-main-form-button" className="reg-btn-main reg-sofia-pro-bold reg-font-size-18">Fortsett</button>
                          </div>
                          {errorMessage ? <p id="churn-page-form-error-message" className="reg-error-message reg-sofia-pro-reg reg-font-size-12 ">{errorMessage}</p> : ""}
                                  {successMessage ? <p className="reg-success-message">{successMessage}</p> : ""}
                          </div>
                        </form>
                      </div>
                    </div>
	);

}

export default SMSForm;