import React, {useState, useEffect} from "react";
import {useStripe, useElements, PaymentElement} from "@stripe/react-stripe-js";
//import { useNavigate  } from "react-router-dom";
import configData from "../../config.json";
import { useNavigate  } from "react-router-dom";
import PaymentService from "../../services/registrering/PaymentService";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#002B1D",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#002B1D"
      },
      "::placeholder": {
        color: "#002B1D"
      }
    },

    invalid: {
      iconColor: "#f41b0b",
      color: "#f41b0b"
    }
  }
}
export default function StripeForm(){
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [success] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);
  const accessToken = localStorage.getItem(process.env.REACT_APP_USER_ACCESS_TOKEN);
   
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
   
    console.log("storeintentsecret", localStorage.getItem("storeintentsecret"));
    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(localStorage.getItem("storeintentsecret"))
      .then(({setupIntent}) => {
        console.log("setupIntent", setupIntent)
        switch (setupIntent.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved. ');
            const subscriptData = {
              "product_id": productId,
              "utm_campaign": "",
              "transaction_id": setupIntent.payment_method
            }
            PaymentService.stripePayment(accessToken, subscriptData)
            .then((response) => {
              navigate(configData.REGISTRATION_COMPLETE_PAGE);
              //break;
            })
            break;

          case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            //setMessage('Failed to process payment details. Please try another payment method.');
            break;
           default:
        }
      }).catch(function(error) {
        console.log('Error creating SetupIntent:', error);
      });;
  });

  const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: process.env.REACT_APP_REGISTERING_URL+process.env.REACT_APP_PAYMENT_PAGE,
        },
      });

      if (error) {
        console.log(error.message)
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      

      
  }


  

  return(
    <>
      <div id="stripe-form-container">
        <div  id="stripe-form-error-msg" className="error-message"> {message} </div> {!success ? <form id="stripe-main-form" onSubmit={handleSubmit}>
          <div id="stripe-form-elements" className="stripe_payment_form">
            <PaymentElement options={CARD_OPTIONS} />
          </div>
          <div id="stripe-form-lb">
            <br />
          </div>
          <div className="submit-button" id="stripe-form-submit-button">
            <button id="stripe-form-button" className="btn-main payment-submit-button sofia-pro-bold font-size-20" name="payment_strex">
              <div className="spinner hidden" id="spinner"></div>
              <span id="button-text ">FULLFØR</span>
              
            </button>
            {errorMessage && <div>{errorMessage}</div>}
          </div>
        </form> : <div id="stripe-form-success-message" className="payment-success-message">{configData.PAYMENT_SUCCESS}</div> }
      </div>
    </>
    )
} 