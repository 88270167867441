import { BrowserRouter, Route, Routes} from "react-router-dom";
import React, { useState } from 'react';

//NEW MINSIDE COMPONENTS
import Header from './components/minkonto/Header';
import HomePage from './components/minkonto/HomePage';
import ChangeSub from './components/minkonto/ChangeSub';
import Campaign from './components/minkonto/Campaign';
import Subscription from './components/minkonto/Subscription';
import CampaignProduct from './components/minkonto/CampaignProduct';
import FabelFamilie from './components/minkonto/FabelFamilie';
import FabelBasis from './components/minkonto/FabelBasis';
import Stopped from './components/minkonto/Stopped';
import StoppedReactive from './components/minkonto/StoppedReactive';
import StopSubscription from './components/minkonto/StopSubscription';
import Login from './components/minkonto/Login';
import MinForgotPassord from './components/minkonto/MinForgotPassord';
import MyAccount from './components/minkonto/MyAccount';
import Payment from './components/minkonto/Payment';
import PaymentCard from './components/minkonto/PaymentCard';
import StrexPayment from './components/minkonto/StrexPayment';
import VippsPayment from './components/minkonto/VippsPayment';
import PurchaseHistory from './components/minkonto/PurchaseHistory';
import Exit from './components/minkonto/Exit';
import NotFound from './components/minkonto/NotFound.js';
import './styles/minkonto/main.css';


//NEW REGISTERING COMPONENTS
import Confirm from "./components/registrering/Confirm.js";
import RegPayment from "./components/registrering/Payment.js";
import Products from "./components/registrering/Products.js";
import UTMProducts from "./components/registrering/UTMProducts.js";
import RegisterComplete from "./components/registrering/RegisterComplete.js";
import ChurnLogin from "./components/registrering/ChurnLogin.js";
import RegForgotPassword from "./components/registrering/ForgotPassword.js";
import ResetPassword from "./components/registrering/ResetPassword.js";
import Partner from "./components/registrering/Partner.js";
import YesToNewsletter from "./components/registrering/YesToNewsletter.js";
import SingleProduct from "./components/registrering/SingleProduct.js";
import VippsSuccess from "./components/registrering/VippsSuccess.js";
import Logout from "./components/registrering/Logout.js";
import "./styles/registrering/main.css";
import "./styles/registrering/product-selector.css";
import "./styles/registrering/churnlogin.css";


function App() {
  const pthname =  window.location.pathname;
  return (
    <div >
    
    <BrowserRouter>
    {pthname.includes("minkonto")==true && (
    <Header />
    )}
      <Routes>

        <Route exact path="/minkonto" element={<HomePage />}   />
        <Route exact path="/minkonto/changesub" element={<ChangeSub />}/>
        <Route exact path="/minkonto/campaign" element={<Campaign />} />
        <Route exact path="/minkonto/subscription" element={<Subscription />} />
        <Route exact path="/minkonto/fabelfamilie" element={<FabelFamilie />} />
        <Route exact path="/minkonto/fabelbasis" element={<FabelBasis />} />
        <Route exact path="/minkonto/campaignproduct" element={<CampaignProduct />} />
        <Route exact path="/minkonto/stopsubscription" element={<StopSubscription />} />
        <Route exact path="/minkonto/stopped" element={<Stopped />} />
        <Route exact path="/minkonto/reactive" element={<StoppedReactive />} />
        <Route exact path="/minkonto/logg-inn" element={<Login />} />
        <Route exact path="/minkonto/glemtpassord" element={<MinForgotPassord />} />
        <Route exact path="/minkonto/minkonto" element={<MyAccount />} />
        <Route exact path="/minkonto/betaling" element={<Payment />} />
        <Route exact path="/minkonto/betalingskort" element={<PaymentCard />} />
        <Route exact path="/minkonto/strexbetaling" element={<StrexPayment />} />
        <Route exact path="/minkonto/vippsbetaling" element={<VippsPayment />} />
        <Route exact path="/minkonto/kjopshistorikk" element={<PurchaseHistory />} />
        <Route exact path="/minkonto/avsluttabonnement" element={<Exit />} />
        <Route path="*"  element={<NotFound />} />

        //REGISTERING ROUTES
        <Route path="/vipps-success" element={<VippsSuccess />} />
        <Route path="/" element={<Products />} />
        <Route path="/partner/:partner" element={<Partner />} />
        <Route path="/:producttag" element={<SingleProduct />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/payment" element={<RegPayment />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/utm/:utmcode" element={<UTMProducts />} />
        <Route path="/productsdemo" element={<Products />} />
        <Route path="/churnlogin" element={<ChurnLogin />} />
        <Route path="/registration-complete" element={<RegisterComplete />} />
        <Route path="/forgot-password" element={<RegForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/ja-til-nyhetsbrev" element={<YesToNewsletter />} />
        
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
