import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
function PartnerProduct(match) {
	

return (
    <>
    <div className="signup-left">
      <div className="part-prd-prc">
        <h1>{match.productinfo.discount.percentage}%</h1>
        <p>rabatt</p>
      </div>
      <div className="part-prd-ttl">
      {match.productinfo.name}
      </div>
      <hr className="part-fabel-hr" />
      <div className="part-prd-subttl">
      {match.productinfo.subtitle}
      </div>
      <div class="part-prd-slogan">
      <p>Del lyttegleden med hele familien</p>
      </div>
      
    </div>
    <div className="part-features " id="product-page-container-row-two-row-five">
      <div className="col-12" id="product-page-container-row-two-row-five-column">
          <div id="product-page-container-features-0-text-container" className="product-list-element part-product-list-element sofia-pro-reg font-size-18 font-color-green ">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" className="svg-inline--fa fa-circle font-size-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="product-page-container-features-0-icon">
          <path fill="#554387" d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"></path></svg>
          <p id="product-page-container-features-0-text">{match.productinfo.streams}</p></div>
          
          <div id={"product-page-container-features-00-text-container"}  key="00" className="product-list-element part-product-list-element  sofia-pro-reg font-size-18 font-color-green ">
            <FontAwesomeIcon icon={faCircle} className="font-size-10" id={"product-page-container-features-00-icon"}  />
            <p id={"product-page-container-features-00-text"} >
              <div>
                <p className="part-prd-pric-fade">Ordinær pris: {match.productinfo.discount.original_price} kr/mnd</p>
                <p>Nå til {match.productinfo.price} kr/mnd</p>
              </div>
            </p>
          </div>
              {match.productinfo.features ? 
            <>
            {match.productinfo.features.included.map((feature, fkey) =>( 
                <div id={"product-page-container-features-"+fkey+"-text-container"}  key={"0-"+fkey} className="product-list-element part-product-list-element  sofia-pro-reg font-size-18 font-color-green ">
                <FontAwesomeIcon icon={faCircle} className="font-size-10" id={"product-page-container-features-"+fkey+"-icon"}  />
                <p id={"product-page-container-features-"+fkey+"-text"} >{feature}</p>
                </div> 
            ))}

            {match.productinfo.features.not_included==null ? "" : 
              
              match.productinfo.features.not_included.map((feature, fkey) =>(
                <>
                <div id={"product-page-not_included-container-features-"+fkey+"-text"} key={"1-"+fkey} className="product-list-element part-product-list-element  not-included-feature sofia-pro-reg font-size-18 x-field ">
                  <FontAwesomeIcon id={"product-page-not_included-container-features-"+fkey+"-icon"} icon={faTimes} className="font-size-16 fontawesome-times font-size-16" />
                  <p id={"product-page-not_included-container-features-"+fkey+"-text"}>{feature}</p>
                </div> 
                </> 
              ))
              
            }
            </>
          : "" }
      </div>
    </div>
    
    </>
	);

}

export default PartnerProduct;