import configData from "../../config.json";
import {useEffect} from "react";
import { useNavigate  } from "react-router-dom";

function Logout() {
	 const navigate = useNavigate();
		useEffect(() => {
		    localStorage.setItem(process.env.REACT_APP_USER_ACCESS_TOKEN, '')
		    localStorage.setItem(configData.STORAGE_MSISDN, '')
		    localStorage.setItem(configData.STORAGE_EMAIL, '')
		    localStorage.setItem(configData.STORAGE_PRODUCT_TAG, '')
		    localStorage.setItem(configData.STORAGE_ORDER_PRICEL, '')
		    localStorage.setItem(configData.UTM_WEEKS, '')
		    localStorage.setItem(configData.EXISTING_USER, '')
		    localStorage.setItem("storeintentsecret", '')
		    navigate(configData.PRODUCT_PAGE);
		    
		  })
	  return (
		<></>
	  );
}

export default Logout;

