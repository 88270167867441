import {NavLink, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import UserService from "../../services/minkonto/UserService";
import AuthService from "../../services/minkonto/AuthService";

function Payment() {
   
    document.body.style.backgroundColor = '#002B1D';
    const [redirect, setRedirect] = useState(null);
    //const [firstname, setFirstname] = useState("");
    ///const [lastname, setLastname] = useState("");
   // const [email, setEmail] = useState("");
    const [paymentType, setPaymentType] = useState("");
    //const [userReady, setUserReady] = useState(false);
    const [currentUser, setCurrentUser] = useState([]);
    const [subscription, setSubscription] = useState();
    const [currentProduct, setSelectedProductID] = useState();
    //const [subscriptionReady, setSubscriptionReady] = useState(false);
    useEffect(() => {
      const currentToken = AuthService.getCurrentToken();
      if (!currentToken) {
        setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
      } else {
        
        

        UserService.getUserFromToken(currentToken.access_token).then(
        (user) => {
          //setFirstname(user.firstname);
          //setLastname(user.lastname);
          //setEmail(user.email);
          setPaymentType(user.payment_type);
          setCurrentUser(user);
          //setUserReady(true);
        },
        (error) => {
          setRedirect({ redirect: process.env.REACT_APP_MINKONTO_URL });
        });

        UserService.getSubscription(currentToken.access_token).then(
        (response) => {
          setSubscription(response);
          setSelectedProductID(response.product_id);
          //setSubscriptionReady(true);
        },
        (error) => {
         
          //setSubscriptionReady(true);
        });
        
    
        
      }
    }, []);
    const vippsData = {
      "product_id": currentProduct,
      "campaign_code": "UTMCAMPAIGN",
      "redirect_url": window.location.origin+process.env.REACT_APP_MINKONTO_URL+"/betaling"
    }
    
    console.log(vippsData);
    const handleVippsPayment = () => {
      const currentToken = AuthService.getCurrentToken();
      UserService.vippsIntent(currentToken.access_token, vippsData)
      .then((response) => {
          if(response.status===200){
            window.sessionStorage.setItem('vippsreference', response.data.reference);
            window.location.href = response.data.confirmation_url
            //setVippsButtonUrl(vipps.data.confirmation_url);
          }
          
      }, (error) => {
          
      })
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // Ensure day and month are displayed with leading zeros if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      return `${formattedDay}.${formattedMonth}.${year}`;
    };
     if (redirect) {
      return <Navigate  to={redirect.redirect} />;
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="container content_green">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="row no-padding">
                        <div className="col-lg-6">
                            <div className="col-12 fm-accordion-textarea" >
                                <h1>Min konto</h1>
                              <p>
                                  {currentUser ? currentUser.firstname+' '+currentUser.lastname : null}<br />
                                  Mobilnummer: {currentUser ? currentUser.mobile : null}<br />
                                  E-post:  {currentUser ? currentUser.email : null}
                              </p>
                              
                              {subscription ? 
                              <p>
                                  <span>Aktiv abonnement: {subscription.product_id}</span><br />
                                  Neste betaling: {formatDate(subscription.next_billing_date)}
                              </p>
                              : '' }    
                                
                                
                            </div>
                            
                            <div className="col-12 col-sm-12 col-lg-10 fm-accordion-area d-sm-block d-lg-block">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn  ">
                                        <div className="col-10"><h1>Abonnement</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/minkonto"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn ">
                                        <div className="col-10"><h1>Konto</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                                
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/betaling"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn fm-accordion-btn-active">
                                        <div className="col-10"><h1>Betaling</h1></div>
                                        <div className="col-2"><h2>-</h2></div>
                                    </div>
                                </NavLink>
                                <div className="d-lg-none col-12 col-sm-12 col-lg-12 fm-accordion-content-mobile">
                                    <p className="p1">VALGT BETALINGSMETODE</p>
                                    <p className="p2">Betalingkort som slutter med 1234</p>
                                    
                                    <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL+"/changesub"} >Endre betalingsmetode</NavLink>
                                </div>
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/kjopshistorikk"}>
                                    <div className=" col-12 col-sm-12 col-lg-12 row fm-accordion-btn">
                                        <div className="col-10"><h1>Kjøpshistorikk</h1></div>
                                        <div className="col-2"><h2>+</h2></div>
                                    </div>
                                </NavLink>
                            </div>
                          </div>
                          <div className="col-lg-6 d-none d-lg-block">
                            <div className="row fm-accordion-content align-middle">
                                <div className="col-10 my-account-fields" >
                                    <h1>Betaling</h1>

                                    <div className="payment-reg">VALGT BETALINGSMETODE</div>
                                    <div><br /></div>
                                    {paymentType===0 ? 
                                      <>
                                        <NavLink className="strex-button"  ></NavLink>
                                        <div><br /></div>
                                        <div className="payment-reg-small">Velger du &aring; betale med mobileregning trenger du ikke &aring; registere kortinformasjon.<br />
                                        Abonnementet vil bli belaster mobilregningen din.<br />
                                        Oversikt over alle transaksjoner finner du p&aring; <NavLink target="_blank" rel="noopener noreferrer" className="boldundlinks" to={"https://strex.no"}>strex.no</NavLink></div>
                                        <div><br /></div>
                                        <div className="payment-reg">ENDRE BETALINGSMETODE</div>
                                        <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL+"/betalingskort"} >Legg til nytt betalingskort</NavLink>
                                        <NavLink className="vipps-button" onClick={() => handleVippsPayment()}  ></NavLink>
                                        <div><br /></div>
                                      </> 
                                      : 
                                      <>
                                      </>
                                    }
                                    {paymentType===6 ? 
                                      <>
                                      <NavLink className="vipps-button"  ></NavLink>
                                      <div><br /></div>
                                      <div><br /></div>
                                      <div className="payment-reg">ENDRE BETALINGSMETODE</div>
                                      <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL+"/betalingskort"} >Legg til nytt betalingskort</NavLink>
                                      <div><br /></div>
                                      <div className="payment-reg">Betal med mobilbetaling</div>
                                      <NavLink className="strex-button" to={process.env.REACT_APP_MINKONTO_URL+"/strexbetaling"}  ></NavLink>
                                      <div><br /></div>
                                      <div className="payment-reg-small">Velger du &aring; betale med mobileregning trenger du ikke &aring; registere kortinformasjon.<br />
                                      Abonnementet vil bli belaster mobilregningen din.<br />
                                      Oversikt over alle transaksjoner finner du p&aring; <NavLink target="_blank" rel="noopener noreferrer" className="boldundlinks" to={"https://strex.no"}>strex.no</NavLink></div>
                                      
                                      </> : ''
                                    }
                                    {paymentType===3 ? 
                                      <>
                                        <div className="payment-bold">Betalingkort som slutter med 1234</div>
                                        <div><br /></div>
                                        <div className="payment-reg">ENDRE BETALINGSMETODE</div>
                                        <NavLink className="fm-bott-button"  >Legg til nytt betalingskort</NavLink>
                                        <NavLink className="vipps-button" onClick={() => handleVippsPayment()}   ></NavLink>
                                        <div><br /></div>
                                        <div className="payment-reg">Betal med mobilbetaling</div>
                                        <NavLink className="strex-button" to={process.env.REACT_APP_MINKONTO_URL+"/strexbetaling"}  ></NavLink>
                                        <div><br /></div>
                                        <div className="payment-reg-small">Velger du &aring; betale med mobileregning trenger du ikke &aring; registere kortinformasjon.<br />
                                        Abonnementet vil bli belaster mobilregningen din.<br />
                                        Oversikt over alle transaksjoner finner du p&aring; <NavLink target="_blank" rel="noopener noreferrer" className="boldundlinks" to={"https://strex.no"}>strex.no</NavLink></div>
                                      
                                      </> : ''
                                    }
                                    <div><br /></div>
                                    <div><br /></div>
                                    <h1>Gavekort/Verdikode</h1>
                                    <div className="payment-reg">Her kan du l&oslash;se inn gavekort eller verdikode.<br />
                                    Abonnementet starter automatisk ved innl&oslash;ing av koden.</div>
                                     <div><br /></div>
                                    <div >
                                        <input type="text"  className="fm-login-form-input input-round-corner" />
                                    </div>
                                    <div align="center">
                                      <NavLink className="fm-login-btn" to={process.env.REACT_APP_MINKONTO_URL+"/logg-inn"} >L&oslash;s inn</NavLink>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                
                
                
                
            </div>
        </div>
    )
}
export default Payment;
  