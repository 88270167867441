import {NavLink } from 'react-router-dom';
function MinForgotPassord() {
    document.body.style.backgroundColor = '#002B1D';
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="col-12"><h1 className="fm-login-heading">ENDRE PASSORD</h1></div>
                    <div className="col" style={{paddingTop: '28px'}}></div>
                </div>
                <div className="container content_green">
                    <div className="row" align="center">
                        
                        
                        <div className="col-lg-4 col-sm-12" align="center">
                            
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="login-form-fields"  align="left">
                                <div>
                                <p>Skriv inn telefonnummeret du bruker til å logge inn i Fabel så sender vi deg instruksjoner for å lage et nytt passord.</p>
                                </div>
                                <div><br /></div>
                                
                                <div className="fm-login-form-label">
                                    MOBILNUMMER
                                </div>

                                <div >
                                    <input type="text" className="fm-login-form-input" />
                                </div>

                               

                                <div align="center">
                                <NavLink className="fm-login-btn" to={process.env.REACT_APP_MINKONTO_URL+"/logg-inn"} >Send SMS</NavLink>
                                </div>
                                <div align="center">
                                <NavLink to={process.env.REACT_APP_MINKONTO_URL+"/logg-inn"} className="fm-forgot-pass-link" >Tilbake til innlogging?</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            
                        </div>
                    </div>
                    
                    
                    
                </div>
                
                
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12" style={{padding: '20px 0px'}}  align="center">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default MinForgotPassord;
  