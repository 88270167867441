import Header from "./Header.js";
import Footer from "./Footer.js";
import Register from "./Register.js";
import BaseProduct from "./BaseProduct.js";
import CampaignProduct from "./CampaignProduct.js";
import PartnerProduct from "./PartnerProduct.js";
import configData from "../../config.json";
import { useNavigate, useParams  } from "react-router-dom";
import React,{useEffect , useState} from "react";
import "../../styles/registrering/homepage.css";
import ProductService from "../../services/registrering/ProductService";
import setBodyColor from './setBodyColor'
function SingleProduct() {
  const navigate = useNavigate();
  const {producttag} = useParams();
  localStorage.setItem(configData.STORAGE_PRODUCT_TAG, producttag);
  const [productInfo, setproductInfo] = useState({}); 
 
  console.log(localStorage.getItem(configData.UTM_WEEKS));
  
  useEffect(() => {
    if (producttag === "undefined" || producttag === null) {
      navigate(configData.PRODUCT_PAGE);
    }
    ProductService.getProductByTag(producttag).then(
      (product) => {
        //==console.log("product", product);
        setproductInfo(product);
        localStorage.setItem(configData.PRODUCT_BACKGROUND, product.product_background);
      }
    );

  }, [producttag, navigate])
    //console.log("productInfo", productInfo.campaign_info);
  productInfo && (productInfo.type==='public_campaign_product' || productInfo.type==='partner_product')  ? setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_PURPLE}) : setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN}) ;
    
  return (

    <div id="product-page-main-container" className={productInfo && (productInfo.type==='public_campaign_product' || productInfo.type==='partner_product')  ? 'reg-product_background-light' : 'reg-product_background-dark'}>

      <Header colorFromParent="primary-color-pallete" />
        <div className="reg-container" id="product-page-container" >
          <div className="reg-row" id="product-page-container-row-one">
            <div className="reg-col-12" id="product-page-container-row-one-column">
              <div className="reg-well  reg-text-left" id="product-page-container-row-one-well">
                <a className="reg-font-size-12 reg-tilbake-link" id="product-page-container-row-one-tilbake" href={configData.PRODUCT_PAGE}><u>TILBAKE</u></a>
              </div>
            </div>
          </div>
          <div className="reg-row" id="product-page-container-row-two">
            {productInfo ?
            <div className="reg-left-side" id="product-page-container-row-two-column-one">
              <div className="reg-well reg-text-center" id="product-page-container-row-two-well-one">
                <div className="reg-row" id="product-page-container-row-two-row-one">
                  {productInfo.type==='partner_product' ? 
                      <PartnerProduct productinfo={productInfo} />
                      
                  : productInfo.type==='base_product' ? 
                    <>
                      <BaseProduct productinfo={productInfo} />
                    </>
                  :
                      <CampaignProduct productinfo={productInfo} />
                  }
                </div>

                
                

                
                <div className="reg-row " id="product-page-container-row-two-row-six">
                  <div className="reg-col-12" id="product-page-container-row-two-row-six-column">
                    
                    <div className=" reg-text-center reg-terms_and_cond reg-secondary-color" id="product-page-container-row-two-row-six-well-two">
                      <h5 className="reg-text-left reg-sofia-pro-reg reg-font-size-14 " id="product-page-container-description-container">
                      {productInfo.terms_and_conditions ? 

                         productInfo.terms_and_conditions.terms.map((terms, fkey) =>(
                            <>
                            {localStorage.getItem(configData.UTM_WEEKS)==="" || localStorage.getItem(configData.UTM_WEEKS)===null ? terms :  terms.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}
                            <br /><br />
                            </> 
                          ))
                       : "" }
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : '' }
            <div className="reg-middle-side" id="product-page-container-row-two-column-two">
            &nbsp;
            </div>
            <div className="reg-right-side" id="product-page-container-row-two-column-three">
              <div className="reg-well reg-text-right" id="product-page-container-row-two-column-three-well">
                <div className="reg-row" id="product-page-container-row-two-row-one">
                  <div className="reg-col-12" id="product-page-container-row-two-row-one-column">
                    
                    <Register />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="reg-row" id="product-page-container-row-five">
            <div className="reg-col-12" id="product-page-container-row-five-column">
              <div className="reg-gap" id="product-page-container-row-five-gap"></div>
              <div className="reg-gap" id="product-page-container-row-five-gap"></div>
              <div className="reg-gap" id="product-page-container-row-five-gap"></div>
              <div className="reg-gap" id="product-page-container-row-five-gap"></div>
            </div>
          </div>
        </div>

      <Footer />
      
    </div>
    )
}
export default SingleProduct;