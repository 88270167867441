import {NavLink } from 'react-router-dom';
function ChangeSub() {
document.body.style.backgroundColor = '#E7D0FF';
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="col-12"><h1 className="fm-h1">Administrer abonnement</h1></div>
                    <div className="col" style={{paddingTop: '28px'}}></div>
                </div>
                <div className="container">
                    <div className="row" align="center">
                        
                        
                        <div className="col-lg-4 col-sm-12" align="center">
                            <div className="fm-boxTop">
                                Ditt nåværende abonnement
                            </div>
                            <div className="fm-green-box withtopBar">
                                <h1>Fabel Familie Pluss</h1>
                                <h3>Del lyttegleden med enda flere!</h3>
                                <h2>229 kr<br /><span>/mnd</span></h2>
                                <div className="col-12 fm-box-list" align="left">
                                    <ul>
                                        <li>Fire profiler kan lytte samtidig</li>
                                        <li>Lag egen profil til alle i familien Inkl. barneprofiler</li>
                                        <li>Lytt ubegrenset til alle lydbøker</li>
                                        <li>Ingen bindingstid</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="fm-green-box withouttopBar">
                                <h1>Fabel Familie</h1>
                                <h3>Del lyttegleden med enda flere!</h3>
                                <h2>189 kr<br /><span>/mnd</span></h2>
                                <div className="col-12 fm-box-list" align="left">
                                    <ul>
                                        <li>To profiler kan lytte samtidig</li>
                                        <li>Lag egen profil til alle i familien Inkl. barneprofiler</li>
                                        <li>Lytt ubegrenset til alle lydbøker</li>
                                        <li>Ingen bindingstid</li>
                                    </ul>
                                </div>
                                <div className="col-12" align="center">
                                    <div className="fm-box-btn" align="center"><NavLink to={process.env.REACT_APP_MINKONTO_URL+"/fabelfamilie"}>Velg</NavLink></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="fm-green-box withouttopBar">
                                <h1>Fabel Basis</h1>
                                <h3 style={{marginTop:'-13px'}}>Hør så mange lydbøker du vil!</h3>
                                <div className="tag-line">Uten tilgang til barn-og ungdomsbøker</div>
                                <h2>149 kr<br /><span>/mnd</span></h2>
                                <div className="col-12 fm-box-list" align="left">
                                    <ul>
                                        <li>Én profil kan lytte om gangen</li>
                                        <li>Mulig å lage flere profiler</li>
                                        <li>Tusenvis av lydbøker og serier, bortsett fra lydbøker for barn og ungdom</li>
                                        <li>Ingen bindingstid</li>
                                    </ul>
                                </div>
                                <div className="col-12" align="center">
                                    <div className="fm-box-btn" align="center"><NavLink to={process.env.REACT_APP_MINKONTO_URL+"/fabelbasis"}>Velg</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div  className="col-lg-1" ></div>
                    
                </div>
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12"  align="center">
                            <NavLink className="fm-bott-button" to={process.env.REACT_APP_MINKONTO_URL+"/subscription"} >Tilbake til Min konto</NavLink>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12"  align="center">
                            <NavLink className="fm-bott-link" to={process.env.REACT_APP_MINKONTO_URL+"/campaign"} >Stopp abonnementet</NavLink>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default ChangeSub;
  