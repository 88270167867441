import axios from "axios";
import configData from "../../config.json";
const API_URL = configData.API_SERVER_URL;
class ProductService {
    
    getProductById(productId) {
        const options = {
            method: "GET",
            
            url:  API_URL + "/product/" +productId
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getProductByTag(productTag) {
        const options = {
            method: "GET",
            
            //url:  API_URL + "/product/" +productTag
            url:  process.env.REACT_APP_API_SERVER_URL+"v2/products/tag/" +productTag
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getAllProducts() {
        const options = {
            method: "GET",
            
            //url:  API_URL + "/products"
            url:  process.env.REACT_APP_API_SERVER_URL+"v2/products/base"
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getAllUtmProducts(utmcode) {
        const options = {
            method: "GET",
            
            //url:  API_URL + "/products"
            url:  process.env.REACT_APP_API_SERVER_URL+"v2/products/base/"+utmcode
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getRegistrationCompleteByProductId(productId) {
        const options = {
            method: "GET",
            url: "http://3.120.153.98:3001/registrationcomplete/" +productId
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }

    getPartnerProductId(partnerid) {
        const options = {
            method: "GET",
            
            url:  process.env.REACT_APP_API_SERVER_URL+"v2/products/tag/" +partnerid
            //url:  API_URL + "/partnerproduct/" +partnerid
        }
        return axios(options)
            .then(response => {
                return response.data;
            });
    }
    

}

export default new ProductService();