import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import configData from "../../config.json";
function BaseProduct(match) {
	

return (
    <>
    <div className="reg-signup-left">
      <div className="reg-prd-ttl">
      {match.productinfo.name}
      </div>
      <div className="reg-base-prd-subttl">
      {match.productinfo.subtitle}
      </div>
      <div className="reg-prd-prc">
        <h1>{match.productinfo.price} kr</h1>
        <p>/mnd</p>
      </div>
    </div>
    <div className="reg-base-features " id="product-page-container-row-two-row-five">
      <div className="reg-col-12" id="product-page-container-row-two-row-five-column">
          <div id="product-page-container-features-0-text-container" className="reg-product-list-element reg-base-product-list-element reg-sofia-pro-reg reg-font-size-18 reg-font-color-green ">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" className="svg-inline--fa reg-fa-circle reg-font-size-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="product-page-container-features-0-icon">
          <path fill="#002B1D" d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"></path></svg>
          <p id="product-page-container-features-0-text">{match.productinfo.type==="public_campaign_product"  || match.productinfo.type==='partner_product' ? match.productinfo.campaign_info :  match.productinfo.streams}</p></div>
          
          <div id={"product-page-container-features-00-text-container"}  key="00" className="reg-product-list-element reg-base-product-list-element reg-sofia-pro-reg reg-font-size-18 reg-font-color-green ">
            <FontAwesomeIcon icon={faCircle} className="reg-font-size-10" id={"product-page-container-features-00-icon"}  />
            <p id={"product-page-container-features-00-text"} >{localStorage.getItem(configData.UTM_WEEKS)==="" || localStorage.getItem(configData.UTM_WEEKS)===null ? match.productinfo.trial_period : match.productinfo.trial_period.replace(configData.FOUR_WEEKS, localStorage.getItem(configData.UTM_WEEKS)).replace(configData.SIX_WEEKS, localStorage.getItem(configData.UTM_WEEKS))}</p>
          </div>
              {match.productinfo.features ? 
            <>
            {match.productinfo.features.included.map((feature, fkey) =>( 
                <div id={"product-page-container-features-"+fkey+"-text-container"}  key={"0-"+fkey} className="reg-product-list-element reg-base-product-list-element reg-sofia-pro-reg reg-font-size-18 reg-font-color-green ">
                <FontAwesomeIcon icon={faCircle} className="reg-font-size-10" id={"product-page-container-features-"+fkey+"-icon"}  />
                <p id={"product-page-container-features-"+fkey+"-text"} >{feature}</p>
                </div> 
            ))}

            {match.productinfo.features.not_included==null ? "" : 
              
              match.productinfo.features.not_included.map((feature, fkey) =>(
                <>
                <div id={"product-page-not_included-container-features-"+fkey+"-text"} key={"1-"+fkey} className="reg-product-list-element reg-base-product-list-element reg-not-included-feature reg-sofia-pro-reg reg-font-size-18 reg-x-field ">
                  <FontAwesomeIcon id={"product-page-not_included-container-features-"+fkey+"-icon"} icon={faTimes} className="reg-font-size-16 reg-fontawesome-times reg-font-size-16" />
                  <p id={"product-page-not_included-container-features-"+fkey+"-text"}>{feature}</p>
                </div> 
                </> 
              ))
              
            }
            </>
          : "" }
      </div>
    </div>

    </>
	);

}

export default BaseProduct;