import Header from "./Header.js";
import React from "react";

import SMSForm from "./SMSForm.js";


import Footer from "./Footer.js";
import "../../styles/registrering/confirm.css";
import setBodyColor from './setBodyColor'
function Confirm() {
	
	
	
	setBodyColor({color: process.env.REACT_APP_BODY_BACKGROUND_COLOR_GREEN})
	

  return (
	<div id="confirm-page-main-conatiner" className="reg-product_background-dark">
		<Header colorFromParent="primary-color-pallete"  />
		<div className="reg-container" id="confirm-page-conatiner">
			<div className="reg-row" id="confirm-page-row">
				<div className="reg-col-4" id="confirm-page-row-column-one">
				&nbsp;
				</div>
				<div className="reg-col-4" id="confirm-page-row-column-two">
					<div className="reg-well reg-text-right" id="confirm-page-row-well-one">
					&nbsp;
					</div>
					<div className="reg-gap reg-desktop-only"></div>
					<div className="reg-gap reg-desktop-only"></div>
					<div className="reg-gap reg-desktop-only"></div>
					<SMSForm />
					<div className="reg-gap"></div>
					<div className="reg-gap"></div>
					<div className="reg-gap"></div>
					<div className="reg-gap"></div>
					<div className="reg-gap"></div>
				</div>
				<div className="col-4" id="confirm-page-row-column-three">
				&nbsp;
				</div>
				
			</div>

		</div>

		<Footer />
	</div>
  	)
}
export default Confirm;