import {NavLink } from 'react-router-dom';

function ChangeSub() {
document.body.style.backgroundColor = '#E7D0FF';
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="col" style={{paddingTop: '45px'}}></div>
                    <div className="col-12"><h1 className="fm-h1">Er du helt sikker på at du vil forlate oss?</h1></div>
                    <div className="col" style={{paddingTop: '28px'}}></div>
                </div>
                <div className="container">
                    <div className="row" align="center">
                        <div  className="col-lg-2"></div>
                        
                        <div className="col-lg-8 col-sm-12" align="center">
                            
                            <div className="fm-green-box-big">
                                <h1>Ubegrenset lytting til<br />halv pris i 2 måneder!</h1>
                                
                                <div style={{borderBottom: '2px solid #CBE1A2', width: '80%', paddingTop: '29px'}}></div>
                                
                                <h2>Høstkveld + lydbok = </h2>
                                <h3>Ombestem deg nå, og sikre deg koselige<br />kvelder for hele familien i hele høst.</h3>
                                
                                <div className="col-12" align="center">
                                    <div className="fm-bigbox-btn" align="center"><NavLink to={process.env.REACT_APP_MINKONTO_URL+"/campaignproduct"}>Jeg vil ha dette tilbudet</NavLink></div>
                                </div>
                                
                                
                                <div className="col-12" align="center">
                                    <div className="fm-bigBox-link" align="center"><NavLink to={process.env.REACT_APP_MINKONTO_URL+"/avsluttabonnement"}>Stepp abonnementet</NavLink></div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    <div  className="col-lg-2"></div>
                    
                </div>
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12"  align="center">
                            <div className="fm-bott-button"><NavLink to={process.env.REACT_APP_MINKONTO_URL+"/subscription"}>Tilbake til Min konto</NavLink></div>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row" >
                        <div className="col-12" style={{padding: '20px 0px'}}  align="center">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default ChangeSub;
  