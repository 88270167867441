import Header from "./Header.js";
import React,{useEffect} from "react";
import configData from "../../config.json";
import { useNavigate  } from "react-router-dom";
import Footer from "./Footer.js";
import "../../styles/registrering/confirm.css";

function YesToNewsletter() {
	const navigate = useNavigate();
	const productBackground = localStorage.getItem(configData.PRODUCT_BACKGROUND);	
	const productId = localStorage.getItem(configData.STORAGE_PRODUCT_ID);	
	

	useEffect(() => {
		if(productId===null){
		  navigate(configData.PRODUCT_PAGE);
		}
	})
	
	


  return (
	<div className={productBackground}>
		<Header colorFromParent="primary-color-pallete"  />
		<div className="container">
			<div className="row">
				<div className="col-3">
				&nbsp;
				</div>
				<div className="col-5">
					<br />
					<form action="https://app.getvero.com/forms/a6482124a887e018d900771fc9141746" method="post">
					<label for="email">Email</label>
					<input name="email" type="email" />
					<br />
					<label for="user_First_name">First Name</label>
					<input name="user[First_name]" type="text" /> 
					<br />
					<label for="user_phone">Phone</label>
					<input name="user[phone]" type="text" /> 
					<br />
					<input name="redirect_on_success" type="hidden" value="https://test.fabel.no/nyhetsbrev/?e-mail=tommy.johannessen@gmail.com" /> 
					<input type="submit" value="Subscribe" /> 
					</form>
				</div>

				<div className="col-3">
				&nbsp;
				</div>
			</div>
		</div>
		<Footer />
	</div>
  	)
}
export default YesToNewsletter;